import { useEffect, useState } from 'react';
import { useQueryState } from 'hooks/useQueryState';
import { token as tokenService } from 'services/SessionStorage/sessionStorage';
import { network } from 'services/network';
import { useQueryGetUserDetail } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import { useLoginHook } from 'components/Login/loginHook';

export const ExternalLogin = () => {
    const [token] = useQueryState('token');
    const [emailFromQuery] = useQueryState('email');
    const [email, setEmail] = useState<string | undefined>(undefined);
    const { data: user } = useQueryGetUserDetail({
        email: email,
    });
    useEffect(() => {
        if (token && emailFromQuery) {
            tokenService.set(token);
            network.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            setEmail(emailFromQuery);
        }
    }, [token, emailFromQuery]);

    useLoginHook({ user });

    return null;
};
