import { CustomizedRoundIcon } from 'components/common/CustomizedRoundIcon/CustomizedRoundIcon';
import Icon from 'components/common/Icons/icon';
import React, { useState } from 'react';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import {
    deleteWidgetFromDashboard,
    getCurrentDashboardId,
} from 'components/Dashboards/Project/Components/CustomDashboard/customDashboard.utils';
import { useQueryGetDashboards } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQueryCustomDashboard';
import { useParams } from 'react-router-dom';
import { ModalManager } from 'components/Dashboards/Project/Components/CustomDashboard/ModalManager/ModalManager';
import {
    IDashboard,
    IModalConfig,
    IRemoveWidget,
} from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { useDashboardStore } from 'components/Dashboards/Project/Components/CustomDashboard/store/dashboardStore';
import useUserHook from 'hooks/useUserHook';
import { CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { useMutationSetDashboardById } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySetDashboardById';

/**
 * Remove the Widget by ID .Part of widgetTopHeader
 * @param {string}  widgetId - unique ID created when adding a new widget to a specific pinboard.
 *
 * @returns  Remove Widget Modal
 * */

export const RemoveWidget = ({ widgetId }: IRemoveWidget) => {
    const { dashboardId } = useParams<any>();
    const { dashboard: selectedDashboardId } = useDashboardStore((store) => store.selectedDashboard);
    const [modalConfirmManagerOpen, setModalConfirmManagerOpen] = useState(false);
    const { handleSnackBar } = useCustomSnackBar();
    const { data, refetch } = useQueryGetDashboards();
    const dashboards: IDashboard[] = data?.dashboardDetails || [];
    const { mutate: mutateSetDashboardById } = useMutationSetDashboardById();
    const dashboard: IDashboard | undefined = getCurrentDashboardId({ dashboards, dashboardId, selectedDashboardId });

    const { ability } = useUserHook();

    const handleRemoveWidgetFromDashboard = () => {
        const updatedDashboard = deleteWidgetFromDashboard({
            dashboard,
            widgetId,
        });
        dashboard &&
            mutateSetDashboardById(
                { dashboard: updatedDashboard },
                {
                    onSuccess: () => handleSnackBar('Successfully removed!', 'success'),
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                    },
                    onSettled: () => refetch(),
                },
            );
    };

    const modalsConfig: { [key: string]: IModalConfig } = {
        RemoveWidgetResponse: {
            onSaveFunction: () => handleRemoveWidgetFromDashboard(),
            onCloseFunction: () => setModalConfirmManagerOpen(false),
            dashboards,
            value: { dashboard },
        },
    };
    if (!ability.can('update', 'PinboardsEdit')) {
        return null;
    }
    return (
        <>
            <CustomizedTooltip
                tooltipPosition={TooltipPosition.Top}
                tooltipContent={`Remove from ${dashboard?.name}`}
                triggerElement={
                    <CustomizedRoundIcon size={'2rem'} onClick={() => setModalConfirmManagerOpen(true)}>
                        <Icon name={'filter-x-icon'} size={'2rem'} />
                    </CustomizedRoundIcon>
                }
            />
            {modalConfirmManagerOpen && <ModalManager modalsConfig={modalsConfig} modalType={'RemoveWidgetResponse'} />}
        </>
    );
};
