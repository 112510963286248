import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';
import { ISelectedContract, ISelectedProject } from 'components/common/TreeProgramFilter/store/treeHeaderStore.types';

interface Props {
    selectedProject: {
        project: ISelectedProject | null;
        contract: ISelectedContract | null;
    };
    setSelectedProject: (value: Props['selectedProject']) => void;
}

const state: StateCreator<Props> = (set) => ({
    selectedProject: {
        project: null,
        contract: null,
    },
    setSelectedProject: (projectToSelect) => set(() => ({ selectedProject: projectToSelect })),
});

export const useProjectStore = create(persist(state, { name: 'selectedProject' }));
