import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';
import { CustomizedButton, CustomizedFormErrorMessage, MyCustomSelect } from 'components/common';
import { EmailInput } from 'components/Settings/Components/UserProfile/components/EmailInput';
import { FirstNameInput } from 'components/Settings/Components/UserProfile/components/FirstNameInput';
import { LastNameInput } from 'components/Settings/Components/UserProfile/components/LastNameInput';
import { PhoneAndTitleInputs } from 'components/Settings/Components/UserProfile/components/PhoneAndTitleInputs';
import { UpdateImageInput } from 'components/Settings/Components/UserProfile/components/updateImageInput';
import { UserGroups } from 'components/Settings/Components/UserProfile/components/UserGroups';
import { ChangePassword } from 'components/Settings/Components/UserProfile/components/ChangePassword';
import { ResetPassword } from 'components/Settings/Components/UserProfile/components/ResetPassword';
import { ResendInvite } from 'components/Settings/Components/UserProfile/components/ResendInvite';
import { UserStatus } from 'components/Settings/Components/UserProfile/components/UserStatus';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useUserHook from 'hooks/useUserHook';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import {
    TMfaType,
    useMutationUpdateUser,
    useQueryGetUserDetail,
} from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import { useForgotPasswordMutation } from 'components/Settings/Components/UserProfile/queries/useForgotPasswordMutation';
import { useResendInviteMutation } from 'components/Settings/Components/UserProfile/queries/useResendInviteMutation';
import { useQueryGetAllUserGroups } from 'components/Settings/Components/UserGroups/queries/userGroupsQuery';
import { Controller, useForm } from 'react-hook-form';
import { MFA_OPTIONS, USER_STATUS_OPTIONS } from 'components/Settings/Components/UserProfile/userProfile.constants';
import { trimObjectValues } from 'utilitys/helpers/general';
import { settingsConfig } from 'components/Settings/settingsConfig';
import { Prompt } from 'react-router';

const groupsMapper = (item) => ({ id: item.group_id, value: item.group_id, label: item.group_name });

export const EditProfile = () => {
    const history = useHistory();
    const { ability } = useUserHook();
    const isAdmin = ability.can('all', 'Admin');

    const { handleSnackBar } = useCustomSnackBar();
    const [modalShow, setModalShow] = useState<boolean>(false);
    const { email } = useParams<{ email: string }>();
    const { mutate, isLoading } = useMutationUpdateUser();
    const forgotPasswordMutation = useForgotPasswordMutation();
    const resendEmailMutation = useResendInviteMutation();

    const { data: userProfile, refetch: refetchUserProfile } = useQueryGetUserDetail({ email });
    const { data: userGroups } = useQueryGetAllUserGroups({ enabled: ability.can('view', 'UserManagement') });
    const options = useMemo(() => (userGroups ? userGroups?.map(groupsMapper) : []), [userGroups]);
    const {
        formState: { isDirty, errors, isSubmitted },
        register,
        control,
        reset,
        handleSubmit,
    } = useForm<any>();

    useEffect(() => {
        if (userProfile && options) {
            reset({
                email: userProfile.email,
                firstName: userProfile.firstName,
                lastName: userProfile.lastName,
                phone: userProfile.phone,
                title: userProfile.title,
                status: USER_STATUS_OPTIONS.find(
                    (option) => option.value.toLowerCase() === userProfile.status.toLowerCase(),
                ),
                userGroups:
                    options.length > 0
                        ? userProfile.userGroupList.map((item) => options.find((option) => option.label === item))
                        : [],
                mfa: MFA_OPTIONS.find((item) => item.value === userProfile.mfa) || { value: 'off' },
            });
        }
    }, [userProfile, options]);

    const onSubmit = (data) => {
        const trimmedData = trimObjectValues(data);
        const payload = {
            userData: {
                ...trimmedData,
                username: trimmedData.email,
                status: trimmedData.status.value.toUpperCase(),
                mfa: trimmedData.mfa.value,
            },
            userGroups: trimmedData.userGroups.map((item) => item.label),
        };

        mutate(payload, {
            onSuccess: () => {
                handleSnackBar('Successfully saved!', 'success');
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
            },
            onSettled: () => {
                history.push(`/settings/${settingsConfig.userManagement.link}`);
            },
        });
    };

    const handleResetPasswordClick = () => {
        if (userProfile) {
            forgotPasswordMutation.mutate(userProfile.email, {
                onSuccess: () => handleSnackBar('Email Sent', 'success'),
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
            });
        }
    };

    const handleResendInvite = () => {
        if (userProfile) {
            resendEmailMutation.mutate(userProfile.email, {
                onSuccess: () => handleSnackBar('Invite Email Sent', 'success'),
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
            });
        }
    };
    const onUserLeavePage = (): string => {
        return 'You have unsaved changes, are you sure you want to leave?';
    };

    return (
        <WidgetWithTitle id={'userProfileForm_FormTitle'} title={'Edit User'} className={classes.UserProfileContainer}>
            <div className={classes.userProfileForm}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.buttonsWrapper}>
                        <div className={classes.buttonsWrapperLeft}>
                            Please complete all mandatory fields and save user entry
                        </div>
                        <div className={classes.buttonsWrapperRight}>
                            <CustomizedButton
                                size={'large'}
                                color={'primary'}
                                type="submit"
                                id="userProfileFormButtons_Save"
                                isLoading={isLoading}
                                disabled={isLoading}
                            >
                                Save
                            </CustomizedButton>

                            <CustomizedButton
                                size={'large'}
                                color={'secondary'}
                                id="userProfileFormButtons_Cancel"
                                onClick={() => reset()}
                            >
                                Revert Changes
                            </CustomizedButton>
                        </div>
                    </div>
                    <div className={classes.formInputsWrapper}>
                        <EmailInput register={register} errors={errors} />
                        <FirstNameInput register={register} errors={errors} />
                        <LastNameInput register={register} errors={errors} />
                        <PhoneAndTitleInputs register={register} errors={errors} />
                        <UpdateImageInput
                            email={email}
                            fullName={`${userProfile?.firstName} ${userProfile?.lastName}`}
                            imageName={userProfile?.image || ''}
                            callback={refetchUserProfile}
                        />
                    </div>
                    <div className={classes.passwordAndUserGroupSection}>
                        <UserGroups errors={errors} control={control} />
                    </div>
                    {userProfile?.mfa !== 'off' && (
                        <div className={classes.passwordAndUserGroupSection}>
                            <div className={classes.associatedRolesContainer}>
                                <label>Two-factor Authentication Method</label>
                                <Controller
                                    render={({ field }) => (
                                        <MyCustomSelect<ISelectOption<TMfaType>> {...field} options={MFA_OPTIONS} />
                                    )}
                                    name="mfa"
                                    control={control}
                                />
                            </div>
                        </div>
                    )}
                    <div className={classes.bottomActions}>
                        {isAdmin && (
                            <ChangePassword
                                setModalShow={setModalShow}
                                modalShow={modalShow}
                                handleModalClose={() => setModalShow(false)}
                                userEmail={userProfile?.email}
                            />
                        )}
                        <ResetPassword handleResetPasswordClick={handleResetPasswordClick} />
                        <ResendInvite handleResendInvite={handleResendInvite} />
                        <UserStatus control={control} />
                    </div>
                </form>
            </div>
            <Prompt message={onUserLeavePage} when={isDirty && !isSubmitted} />
        </WidgetWithTitle>
    );
};
