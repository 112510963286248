import { GraphPoint } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportTypes';
import moment from 'moment/moment';
import { orderBy } from 'lodash';
import colorsVars from 'styles/colors.module.scss';
import { useThemeStatus } from 'components/Header/store/themeStatus';
const { white, graphAnchorBorderColor } = colorsVars;
import constants from 'components/common/Constants/constants';

export const dataSource = {
    chart: {
        // caption: 'Trend',
        captionAlignment: 'left',
        showYAxisValues: '1',
        showXAxisValues: '0',
        anchorradius: '5',
        plottooltext: '$displayValue: $dataValue',
        showhovereffect: '1',
        tooltipBorderAlpha: '30',
        toolTipBgColor: '#1B1B1B',
        toolTipBorderColor: '#FFFFFF',
        showEntityHoverEffect: '1',
        toolTipBgAlpha: '100',
        theme: 'candy',
        rotateLabels: '1',
        bgcolor: '#1B1B1B',
        palettecolors: '#FFFFFF',
        yAxisMaxvalue: '100',
        yAxisMinvalue: '0',
        numDivLines: 3,
        adjustDiv: 0,
    },
};

export const prepareGraphData = (points: GraphPoint[], getGraphPointColor, currentVersionId) => {
    if (!points) {
        return false;
    }

    const { themeStatus } = useThemeStatus();

    const sortedPoints = orderBy(points, ['versionDate', 'asc']);

    return sortedPoints.map((point: GraphPoint) => {
        const versionDate = moment(point.versionDate).format(constants.formats.date.default);
        const currentVersionStyle = {
            anchorBorderColor: graphAnchorBorderColor,
            anchorBorderThickness: '3',
        };
        const isVersionSelected = currentVersionId === point.versionHashCode?.toString();
        return {
            label: `${point.versionName}(${versionDate})`,
            value: point.score,
            anchorBgColor: getGraphPointColor
                ? getGraphPointColor({ status: point.status, value: point.score, theme: themeStatus })
                : white,
            displayValue: `${point.versionName} (${versionDate})`,
            ...(isVersionSelected ? currentVersionStyle : {}),
        };
    });
};
