import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/components/projectPreferences.module.scss';
import { DefaultBaseLinePerVersion } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/DefaultBaseLinePerVersion/DefaultBaseLinePerVersion';
import { UniqueActivityKey } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/UniqueActivityKey/UniqueActivityKey';
import { useProjectStore } from 'components/common/TreeProgramFilter/store/treeHeaderStore';
import { BaselineComparableProgress } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/BaselineComparableProgress/baselineComparableProgress';

export const ProjectPreferences = () => {
    const { selectedProject } = useProjectStore();

    return (
        <WidgetWithTitle title={'Project Preferences'}>
            <div className={classes.formContainer}>
                <p className={classes.note}>
                    You will be prompted to confirm in case you make any changes to the project preferences.
                </p>
                <div className={classes.inputWrapper}>
                    <DefaultBaseLinePerVersion project={selectedProject.project} />
                </div>
                <div className={classes.inputWrapper}>
                    <UniqueActivityKey project={selectedProject.project} />
                </div>
                <div className={classes.inputWrapper}>
                    <BaselineComparableProgress project={selectedProject.project} />
                </div>
            </div>
        </WidgetWithTitle>
    );
};
