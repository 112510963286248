import React, { useRef, useLayoutEffect, useState } from 'react';
import * as d3 from 'd3';
import classes from './ScatterGraph.module.scss';
import moment from 'moment-timezone';
import { handleGrouping } from 'utilitys/helpers/general';
import colorsVars from 'styles/colors.module.scss';
import { noop } from 'lodash';
import { useThemeStatus } from 'components/Header/store/themeStatus';
import constants from 'components/common/Constants/constants';

const config = {
    backgroundColor: '#000',
    spaceBetweenCircles: 15,
    colors: {
        delayDriver: colorsVars.delayDriverColor,
        inComplete: 'cyan',
        complete: 'green',
    },
};

interface IDatasetItem {
    date: number;
    delayDriver: boolean;
    milestoneStatus: string;
    title: string;
    wbs: number;
    wbsName: string;
    y: number;
}

interface IScatterGraphProps {
    dataset: IDatasetItem[];
    groupBy: string;
    nowDate: Date;
    callback: ({ minDate, maxDate }: { minDate: Date | null; maxDate: Date | null }) => void;
}

const ScatterGraph = ({ dataset = [], groupBy, nowDate, callback = noop }: IScatterGraphProps) => {
    const { themeStatus } = useThemeStatus();
    const textColor = themeStatus ? '#ffffff' : '#000000';

    const svgRef = useRef<SVGSVGElement>(null);
    const xaxisRef = useRef<SVGSVGElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const height = 630;
    const calculatedHeight =
        dataset.length * config.spaceBetweenCircles < height ? height : dataset.length * config.spaceBetweenCircles;
    const margin = { top: 20, right: 10, bottom: 30, left: 80 };

    let idleTimeout;

    const idled = () => {
        idleTimeout = null;
    };

    const calculateTotalGroup = (arr) => {
        let sum = 0;
        const _arr = [0];
        for (let i = 1; i < arr.length; i++) {
            _arr.push(sum + arr[i - 1]);
            sum += arr[i - 1];
        }
        return _arr;
    };

    useLayoutEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setWidth(containerRef.current.offsetWidth - margin.right);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [dataset]);

    useLayoutEffect(() => {
        if (svgRef.current) {
            const data = dataset.sort((a, b) => (a[groupBy] < b[groupBy] ? -1 : a[groupBy] > b[groupBy] ? 1 : 0));
            const svg = d3.select(svgRef.current);
            const leftAxis = svg.select('.leftAxis');
            const bottomAxis = d3.select(xaxisRef.current).select('.bottomAxis');
            const today = nowDate || new Date();

            const draw = () => {
                const groups = handleGrouping(data, groupBy);
                const groupingValues = calculateTotalGroup(Object.values(groups).map((v) => v.length));
                const mindate = d3.min(dataset.map((d) => d.date));
                const maxdate = d3.max(dataset.map((d) => d.date));
                const x = d3
                    .scaleTime()
                    .domain([mindate, maxdate])
                    .range([margin.left + 10, width - margin.right - 10]);

                const xAxis = (g) => g.attr('transform', `translate(0,0)`).call(d3.axisBottom(x));

                const y = d3
                    .scaleLinear()
                    .domain([0, data.length])
                    .range([margin.top, calculatedHeight - margin.bottom]);

                const yAxis = (g) => {
                    return g.attr('transform', `translate(${margin.left},0)`).call(
                        d3
                            .axisRight(y)
                            .ticks(Object.keys(groups).length)
                            .tickValues(groupingValues)
                            .tickFormat((_, index) => `${Object.keys(groups)[index]}`),
                    );
                };

                leftAxis.call(yAxis);
                leftAxis.selectAll('.tick line').attr('stroke', textColor);
                leftAxis.selectAll('.tick text').attr('fill', textColor).attr('font-size', '14');
                leftAxis.select('path').attr('stroke', textColor);

                bottomAxis.call(xAxis);
                bottomAxis.selectAll('.tick line').attr('stroke', textColor);
                bottomAxis.selectAll('.tick text').attr('fill', textColor).attr('font-size', '14');
                bottomAxis.select('path').attr('stroke', textColor);

                const updateChart = (event) => {
                    const extent = event.selection;
                    if (!extent) {
                        if (!idleTimeout) return (idleTimeout = setTimeout(idled, 350)); // This allows to wait a little bit
                        callback({ minDate: null, maxDate: null });
                        x.domain([mindate, maxdate]);
                    } else {
                        callback({ minDate: x.invert(extent[0]), maxDate: x.invert(extent[1]) });
                        x.domain([x.invert(extent[0]), x.invert(extent[1])]);
                        svg.select('.brush').call(brush.move, null); // This remove the grey brush area as soon as the selection has been done
                    }

                    bottomAxis.transition().duration(1000).call(d3.axisBottom(x));
                    bottomAxis.selectAll('.tick line').attr('stroke', '#ffffff');
                    bottomAxis.selectAll('.tick text').attr('fill', '#ffffff').attr('font-size', '16');
                    bottomAxis.select('path').attr('stroke', '#ffffff');

                    // Update circles position
                    svg.select('.circles')
                        .selectAll('circle')
                        .transition()
                        .duration(1000)
                        .attr('cx', (d) => x(d.date));

                    svg.select('.today-line')
                        .selectAll('line')
                        .transition()
                        .duration(1000)
                        .attr('x1', x(today))
                        .attr('y1', 0)
                        .attr('x2', x(today));

                    svg.select('.today-line')
                        .selectAll('rect')
                        .transition()
                        .duration(1000)
                        .attr('x', x(today) - 50);

                    svg.select('.today-line').selectAll('text').transition().duration(1000).attr('x', x(today));
                };

                const t = svg.transition().duration(500).delay(300);
                const tooltip = d3
                    .select('#tooltip')
                    .style('opacity', 0)
                    .style('width', '300px')
                    .style('position', 'absolute')
                    .style('background-color', 'white')
                    .style('border', 'solid')
                    .style('border-width', '1px')
                    .style('border-radius', '5px')
                    .style('padding', '10px')
                    .style('line-height', '24px')
                    .style('color', '#000000');

                const mouseover = function (event) {
                    d3.select(event.currentTarget).transition().duration(100).attr('stroke-opacity', '0.7');

                    tooltip.style('opacity', 1);
                };

                const mousemove = function (event, d) {
                    d3.select(event.currentTarget).transition().duration(100).attr('stroke-opacity', '0.7');

                    const tooltipHeight = tooltip.node().offsetHeight;
                    const tooltipWidth = tooltip.node().offsetWidth;
                    let leftPos = event.layerX + 50;
                    let topPos = event.layerY;
                    if (chartRef.current) {
                        leftPos =
                            chartRef.current.offsetWidth - margin.right - event.layerX > tooltipWidth
                                ? event.layerX + 50
                                : event.layerX - tooltipWidth - 50;

                        const isOutOfRange = event.layerY + tooltipHeight > chartRef.current.offsetHeight;
                        topPos = isOutOfRange
                            ? event.layerY + chartRef.current.scrollTop - tooltipHeight
                            : event.layerY + chartRef.current.scrollTop;
                    }

                    tooltip
                        .html(`${d.title.replaceAll(',', '<br />')}`)
                        .style('left', leftPos + 'px') // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
                        .style('top', topPos + 'px');
                };

                // A function that change this tooltip when the leaves a point: just need to set opacity to 0 again
                const mouseleave = function (event) {
                    d3.select(event.currentTarget).transition().duration(100).attr('stroke-opacity', '0');

                    tooltip
                        .transition()
                        .duration(0)
                        .style('opacity', 0)
                        .transition()
                        .duration(0)
                        .style('left', '-1000px');
                };

                svg.select('.today-line')
                    .selectAll('line')
                    .attr('x1', x(today))
                    .attr('y1', 0)
                    .attr('x2', x(today))
                    .attr('y2', calculatedHeight)
                    .style('stroke-width', 2)
                    .style('stroke', colorsVars.versionDateColor)
                    .style('fill', 'none');

                svg.select('.today-line')
                    .selectAll('rect')
                    .attr('x', x(today) - 50)
                    .attr('y', height - 25);

                svg.select('.today-line')
                    .selectAll('text')
                    .attr('x', x(today))
                    .attr('y', height - 10)
                    .attr('font-size', '14')
                    .attr('font-family', 'Roboto')
                    .attr('fill', colorsVars.versionDateColor)
                    .attr('dominant-baseline', 'top')
                    .attr('text-anchor', 'middle');

                svg.select('.lines')
                    .selectAll('line')
                    .data(groupingValues)
                    .join(
                        (enter) => {
                            enter
                                .append('line')
                                .attr('x1', () => x(new Date(mindate)))
                                .attr('x2', () => x(new Date(maxdate)))
                                .attr('y1', (d) => y(d))
                                .attr('y2', (d) => y(d))
                                .attr('stroke-dasharray', '5, 10')
                                .style('stroke-width', 2)
                                .style('stroke', '#84878a')
                                .style('fill', 'none');
                        },
                        (update) => {
                            update
                                .attr('x1', () => x(new Date(mindate)))
                                .attr('x2', () => x(new Date(maxdate)))
                                .attr('y1', (d) => y(d))
                                .attr('y2', (d) => y(d))
                                .attr('stroke-dasharray', '5, 10')
                                .style('stroke-width', 2)
                                .style('stroke', '#84878a')
                                .style('fill', 'none');
                        },
                        (exit) => {
                            exit.remove();
                        },
                    );

                const getColor = (d) => {
                    if (d.delayDriver === true) return config.colors.delayDriver;
                    if (d.milestoneStatus === 'Complete') return config.colors.complete;
                    return config.colors.inComplete;
                };

                const getCircleY = (d, i) => {
                    return y(i + 1);
                };

                svg.select('.circles')
                    .selectAll('circle')
                    .data(data)
                    .join(
                        (enter) => {
                            enter
                                .append('circle')
                                .on('mouseover', mouseover)
                                .on('mousemove', mousemove)
                                .on('mouseleave', mouseleave)
                                .attr('r', '5')
                                .attr('stroke-width', '5')
                                .attr('stroke-opacity', '0')
                                .transition(t)
                                .attr('cx', (d) => x(d.date))
                                .attr('fill', (d) => getColor(d))
                                .attr('stroke', (d) => getColor(d))
                                .attr('cy', getCircleY);
                        },
                        (update) => {
                            update
                                .transition(t)
                                .attr('cx', (d) => x(d.date))
                                .attr('cy', getCircleY)
                                .attr('fill', (d) => getColor(d))
                                .attr('stroke', (d) => getColor(d));
                        },
                        (exit) => {
                            exit.transition(t)
                                .attr('cx', (d) => x(d.date))
                                .remove();
                        },
                    );

                const brush = d3
                    .brushX()
                    .extent([
                        [margin.left, margin.top],
                        [width - margin.right, calculatedHeight],
                    ])
                    .on('end', updateChart);

                svg.select('.brush').call(brush);
            };

            draw();
        }
    }, [width, dataset]);

    if (dataset.length === 0) {
        return null;
    }

    const configWithTheme = {
        ...config,
        backgroundColor: themeStatus ? '#000000' : '#ffffff',
    };

    return (
        <div ref={containerRef} className={classes.container}>
            <div ref={chartRef} style={{ backgroundColor: configWithTheme.backgroundColor }} className={classes.chart}>
                <svg ref={svgRef} height={calculatedHeight} viewBox={`0 0 ${width} ${calculatedHeight}`}>
                    <defs>
                        <clipPath id="scatterGraph">
                            <rect
                                x={margin.left - 5}
                                y={margin.top}
                                width={width - margin.right - margin.left + 10}
                                height={calculatedHeight - margin.top}
                            />
                        </clipPath>
                    </defs>

                    <g className="brush" style={{ pointerEvents: 'all' }} />
                    <g clipPath="url(#scatterGraph)">
                        <g className="circles" />
                        <g className="lines" />
                        <g className="today-line">
                            <line />
                            <rect width="100" height="20" fill="#ffffff" stroke={colorsVars.versionDateColor} />
                            <text>{moment(nowDate).format(constants.formats.date.default)}</text>
                        </g>
                    </g>
                    <g className="leftAxis" />
                </svg>
                <div id="tooltip" />
            </div>

            <div style={{ backgroundColor: configWithTheme.backgroundColor }}>
                <svg ref={xaxisRef} height={50} viewBox={`0 0 ${width} 50`}>
                    <g className="bottomAxis" />
                </svg>
            </div>
        </div>
    );
};

export default ScatterGraph;
