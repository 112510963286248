import React from 'react';
import { CustomizedButton } from 'components/common/CustomizeButton/CustomizedButton';
import Icon from 'components/common/Icons/icon';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import {
    Details,
    FileExtensions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/programLibrary.types';
import { xerAnalyze, mppVersions, xlsxAnalyze } from 'components/common/FileUploader/utils';
import { useProjectStore } from 'components/common/TreeProgramFilter/store/treeHeaderStore';
import { useProgramLibraryStore } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/store/programLibraryStore';

const FileUploader = () => {
    const { update } = useProgramLibraryStore();
    const {
        selectedProject: { contract },
    } = useProjectStore();
    const { handleSnackBar } = useCustomSnackBar();

    const handleAfterUpload = ({ versions, file, type }: Details) => {
        if (versions.some((item) => item.versionDate === 'Invalid date')) {
            handleSnackBar('Invalid file. Missing Data Date', 'error');
        } else {
            update({ modalShow: true, details: { versions, file, type } });
        }
    };

    const handleFileReadXer = ({ file, fileReader }: { file: File; fileReader: FileReader }) => {
        const versions = xerAnalyze(fileReader);
        handleAfterUpload({ versions, file, type: FileExtensions.xer });
    };
    const handleFileReadMpp = ({ file }) => {
        handleAfterUpload({ versions: mppVersions, file, type: FileExtensions.mpp });
    };
    const handleFileReadXlsx = ({ file }) => {
        if (contract) {
            const versions = xlsxAnalyze(contract.title);
            handleAfterUpload({
                versions,
                file,
                type: FileExtensions.xlsx,
            });
        }
    };

    const handleFileChosen = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target?.files) {
            const file = e.target.files[0];
            const extArr = file.name.split('.');
            const extension = extArr[extArr.length - 1].toLowerCase() as FileExtensions;

            if (!Object.values(FileExtensions).includes(extension)) {
                handleSnackBar('Unsupported file type', 'error');
                return;
            }

            const fileReader = new FileReader();
            if (extension === FileExtensions.xer) {
                fileReader.onloadend = () => handleFileReadXer({ file, fileReader });
                fileReader.readAsText(file);
            }

            if (extension === FileExtensions.mpp) {
                fileReader.onloadend = () => handleFileReadMpp({ file });
                fileReader.readAsArrayBuffer(file);
            }

            if (extension === FileExtensions.xlsx) {
                fileReader.onloadend = () => handleFileReadXlsx({ file });
                fileReader.readAsArrayBuffer(file);
            }
            // must clean input so after closing modal user can open it again
            e.target.value = '';
        }
    };

    return (
        <CustomizedTooltip
            tooltipPosition={TooltipPosition.Top}
            tooltipContent={<div>{`Upload File`}</div>}
            triggerElement={
                <CustomizedButton
                    isRoundButton={true}
                    size={'small'}
                    color={'primary'}
                    component={'label'}
                    id={'program-library-upload-file-button'}
                >
                    <input
                        type="file"
                        id="file"
                        accept=".xer, .mpp, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        hidden
                        onChange={(e) => handleFileChosen(e)}
                    />
                    <Icon name="plus-icon" size={'2.2rem'} color="#ffffff" />
                </CustomizedButton>
            }
        />
    );
};

export default FileUploader;
