import classes from 'components/common/GlobalFilterComponent/GlobalFilterComponent.module.scss';
import { FilterLabels } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { Controller } from 'react-hook-form';
import { MyCustomSelect } from 'components/common/MyCustomSelect/myCustomSelect';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ProgramStatusUrlParams } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportTypes';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useQueryState } from 'hooks/useQueryState';
import { useGlobalFilterDataStore } from 'components/common/GlobalFilterComponent/store/globalFilterDataStore';
import { useDebounce } from 'hooks/useDebaunce';
import {
    useQueryGetSingleWbs,
    useQueryGetWbs,
} from 'components/common/GlobalFilterComponent/queries/GlobalFilterComponentQuery';
import produce from 'immer';
import { mapper } from 'components/common/GlobalFilterComponent/components/WbsFilter/utils';
import { orderBy } from 'lodash';

interface IWbsFilterProps {
    methods?: any;
    name: string;
    setState?: React.Dispatch<React.SetStateAction<any>>;
    handleFilters?: () => void;
    className?: string;
}
export const WbsFilter = ({ methods, name, setState, handleFilters, className }: IWbsFilterProps) => {
    const hashcode = Object.values(useParams<ProgramStatusUrlParams>()).filter(Boolean).join(',');
    const { versionList: versionFromHashMap } = useHashmapConverter(hashcode);
    const latestVersionId = versionFromHashMap[0]?.versionDbId;
    const [queryStringValue] = useQueryState('wbs');
    const { getValueFromStore } = useGlobalFilterDataStore();
    const storeValue = getValueFromStore<string>('wbs');

    const [filter, setFilter] = React.useState('');
    const debouncedValue = useDebounce<string>(filter, 1000);

    const { data: wbs, isFetching } = useQueryGetWbs({
        latestVersion: latestVersionId,
        filter: debouncedValue,
    });

    const options = useMemo<ISelectOption<string>[]>(
        () => (wbs ? orderBy(wbs, [(item: any) => item.activity_id.toLowerCase()], ['asc']).map(mapper) : []),
        [wbs],
    );

    const formValue = methods?.getValues(name);
    const isValue = formValue || queryStringValue || storeValue;

    const { data: singleWbs } = useQueryGetSingleWbs({
        latestVersion: latestVersionId,
        taskId: isValue, // make sure BE can perform search by task_version_hash_code
    });

    useEffect(() => {
        if (setState) {
            if (!isValue) {
                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft.wbs.isReady = true;
                        draft.wbs.isEmpty = true;
                        return draft;
                    });
                });
            }

            if (singleWbs) {
                if (singleWbs.length > 0) {
                    methods?.setValue(name, mapper(singleWbs[0]).value);
                }

                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft.wbs.isReady = true;
                        return draft;
                    });
                });
            }
        }
    }, [isValue, wbs, singleWbs, setState]);

    const handleInputChangePrimary = (inputText, event) => {
        if (event.action !== 'input-blur' && event.action !== 'menu-close' && event.action !== 'set-value') {
            setFilter(inputText);
        }
        if (event.action === 'menu-close') {
            setFilter('');
        }
    };

    const handleChange = (option: ISelectOption<string> | null) => {
        methods?.setValue(name, option ? option.value : null);
        handleFilters && handleFilters();
    };

    const handleValue = (value) => {
        if (!value) {
            return null;
        }
        if (singleWbs && singleWbs.length > 0 && mapper(singleWbs[0]).value === value) {
            return mapper(singleWbs[0]);
        }
        return wbs?.map(mapper).find((item) => item.value === value) || null;
    };
    return (
        <div className={`${classes.selectWrapper} ${className ?? ''}`}>
            <span className={classes.title}>{FilterLabels.WBS_SEARCH}</span>
            <div className={classes.activityCodesSelectWrapper}>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect<ISelectOption<string>>
                            {...field}
                            options={options}
                            id={'global-filter-component-wbs'}
                            onInputChange={handleInputChangePrimary}
                            isClearable={true}
                            isLoading={isFetching}
                            onChange={handleChange}
                            value={handleValue(field.value)}
                            placeholder={'Select WBS or start typing its name...'}
                        />
                    )}
                    name={name}
                    control={methods?.control}
                />
            </div>
        </div>
    );
};
