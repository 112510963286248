import { useQueryState } from 'hooks/useQueryState';
import { useGlobalFilterDataStore } from 'components/common/GlobalFilterComponent/store/globalFilterDataStore';
import { useQueryGetTagsAssignedToCurrentCustomerSubPrograms } from 'components/common/GlobalFilterComponent/queries/GlobalFilterComponentQuery';
import React, { useEffect, useMemo, memo } from 'react';
import { orderBy } from 'lodash';
import produce from 'immer';
import classes from 'components/common/GlobalFilterComponent/GlobalFilterComponent.module.scss';
import { FilterLabels } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { Controller, UseFormReturn } from 'react-hook-form';
import { MyCustomSelect } from 'components/common/MyCustomSelect/myCustomSelect';
import { listStyleWithExclude } from 'components/common/MyCustomSelect/myCustomSelect.style';
import { IState } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';

interface IProps {
    methods?: UseFormReturn<any, any>;
    name: string;
    setState?: React.Dispatch<React.SetStateAction<IState>>;
    handleFilters?: () => void;
}

export const ProgramTagsFilter = memo(({ methods, name, setState, handleFilters }: IProps) => {
    const [queryStringValue] = useQueryState('programTags');

    const { getValueFromStore } = useGlobalFilterDataStore();
    const storeValue = getValueFromStore<ISelectOption<string>[]>('programTags');

    const { data } = useQueryGetTagsAssignedToCurrentCustomerSubPrograms();

    const options = useMemo<ISelectOption<string>[]>(
        () =>
            data
                ? orderBy(
                      data.map((item) => ({ label: item.name, value: item.name })),
                      [(item: any) => item['label'].toLowerCase()],
                      ['asc'],
                  )
                : [],
        [data],
    );

    const isValue = useMemo(() => {
        if (queryStringValue && queryStringValue.length > 0) {
            return queryStringValue;
        } else {
            return storeValue;
        }
    }, [queryStringValue, storeValue]);

    useEffect(() => {
        if (setState) {
            if (!isValue || isValue.length === 0) {
                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft.programTags.isReady = true;
                        draft.programTags.isEmpty = true;
                        return draft;
                    });
                });
            }

            if (isValue && isValue.length > 0 && data) {
                const convertStringToBoolean = isValue.map((item) => {
                    return {
                        ...item,
                        value: item.value,
                    };
                });
                methods?.setValue(name, convertStringToBoolean);

                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft.programTags.isReady = true;
                        return draft;
                    });
                });
            }
        }
    }, [isValue, data, setState]);

    const handleChange = (value: readonly ISelectOption<string>[]) => {
        methods?.setValue(name, value);
        handleFilters && handleFilters();
    };

    const handleValue = (value) => {
        return orderBy(value, (o) => o.value.toLowerCase());
    };

    return (
        <div className={classes.selectWrapper}>
            <span className={classes.title}>{FilterLabels.TAG_PORTFOLIO_BOARD}</span>
            <div data-testid={'program-tags-filter'}>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect<ISelectOption<string>, true>
                            {...field}
                            options={options}
                            isMulti
                            onChange={handleChange}
                            value={handleValue(field.value)}
                            placeholder={'Select Tags...'}
                            styles={listStyleWithExclude}
                        />
                    )}
                    name={name}
                    control={methods?.control}
                />
            </div>
        </div>
    );
});
