import { CheckboxComponent } from 'components/common/CheckboxComponent/checkboxComponent';
import { CustomizedConfirmDeleteModal, CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import ActionMenu from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ActionMenu/ActionMenu';
import React, { useState } from 'react';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/rowOptionsHeader.module.scss';
import { useMutationRemoveXerFile } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryProgramLibrary';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryClient } from 'react-query';
import { RenderActionButtonMenu } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ActionMenu/actionButtonMenu';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { ActionMenuItemWithIcon } from 'components/common/ActionMenuItemWithIcon/actionMenuItemWithIcon';
import { useProgramLibraryTableStore } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/store/programLibraryTableStore';
import { useVersionStore } from 'components/common/TreeProgramFilter/VersionSelect/store/versionSelectStore';
import { useBattlecardsStore } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/store/battlecardsStore';
import { initialFiltersState } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';

export const RowOptionsHeader = ({ toggleAllRowsSelected, isAllRowsSelected, selectedFlatRows }) => {
    const isMultiple = useProgramLibraryTableStore((state) => state.isMultiple);
    const setIsMultiple = useProgramLibraryTableStore((state) => state.setIsMultiple);
    const [showModal, setShowModal] = useState<boolean>(false);
    const { handleSnackBar } = useCustomSnackBar();
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutationRemoveXerFile();

    const { sendEventWithDimensions } = useEventWithDimensions();

    const { setVersion } = useVersionStore();
    const { setFilterData } = useBattlecardsStore();

    const handleDeleteFiles = () => {
        const ids = selectedFlatRows.map((item) => item.original.id);
        mutate(
            { ids },
            {
                onSuccess: () => {
                    handleSnackBar('File was deleted', 'success');
                    setVersion(null);
                    setFilterData(initialFiltersState);

                    sendEventWithDimensions({
                        category: 'Program Library',
                        action: 'Delete Version',
                        label: `version ids: ${ids}`,
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
                onSettled: () => {
                    setShowModal(false);
                    return queryClient.invalidateQueries('getProjectVersions');
                },
            },
        );
    };

    return (
        <>
            <div className={classes.container}>
                {isMultiple && <CheckboxComponent checked={isAllRowsSelected} onChange={toggleAllRowsSelected} />}
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Multiple Selection'}
                    triggerElement={
                        <CustomizedRoundIcon data-testid={'multiple-selection'} size={'3rem'} onClick={() => setIsMultiple()} selected={isMultiple}>
                            <Icon name="multiple-selection" />
                        </CustomizedRoundIcon>
                    }
                />
                {isMultiple && (
                    <>
                        <ActionMenu
                            versionIdList={selectedFlatRows.map((item) => item.original.versionHashCode)}
                            status="ok"
                            errorMessage={''}
                            isMultiple={true}
                            id={`viewAnalytics_multiple`}
                        />
                        <RenderActionButtonMenu id={`threeDots_multiple`} tooltipContent={'Delete Files'}>
                            <ActionMenuItemWithIcon
                                handleClick={() => setShowModal(true)}
                                iconText={'Delete'}
                                iconType={'delete-icon'}
                                isActive={selectedFlatRows.length > 0}
                            />
                        </RenderActionButtonMenu>
                    </>
                )}
            </div>
            <CustomizedConfirmDeleteModal
                isShowModal={showModal}
                hideModal={() => {
                    setShowModal(false);
                }}
                text={
                    'Are you sure you want to delete this program version? Note all its associated cards will be deleted.'
                }
                onConfirm={handleDeleteFiles}
                isLoading={isLoading}
            />
        </>
    );
};
