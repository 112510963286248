import { create, StateCreator } from 'zustand';
import { DisplayStyle } from 'components/common/ToggleView/ToggleView';
import { initialFiltersState } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';

interface Props {
    filterData: typeof initialFiltersState;
    setFilterData: (value: any) => void;

    displayStyle: DisplayStyle;
    setDisplayStyle: (value: DisplayStyle) => void;
}
const state: StateCreator<Props> = (set) => ({
    filterData: initialFiltersState,
    setFilterData: (value) => set({ filterData: value }),

    displayStyle: DisplayStyle.ROWS,
    setDisplayStyle: (value) => set({ displayStyle: value }),
});

export const useBattlecardsStore = create(state);
