import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import colorsVars from 'styles/colors.module.scss';
import { useThemeStatus } from 'components/Header/store/themeStatus';
const { doneCardBackground, lightGray, darkGray, white, black } = colorsVars;

interface ICustomizedRoundProgressBarProps extends CircularProgressProps {
    value: number;
    customCircleColor?: string;
    customFontSize?: string;
}

export const CustomizedRoundProgressBar = ({
    value,
    customCircleColor = doneCardBackground,
    customFontSize = '1.2rem',
    ...props
}: ICustomizedRoundProgressBarProps) => {
    const { themeStatus } = useThemeStatus();
    const textColor = themeStatus ? white : black;
    return (
        <Box sx={{ position: 'relative', display: 'flex', width: '4rem' }}>
            <CircularProgress
                variant="determinate"
                sx={{
                    color: themeStatus ? darkGray : lightGray,
                    position: 'absolute',
                    left: 0,
                }}
                value={100}
                {...props}
                style={{ width: '4rem', height: '4rem' }}
            />
            <CircularProgress
                variant="determinate"
                value={value}
                {...props}
                sx={{
                    color: customCircleColor,
                }}
                style={{ width: '4rem', height: '4rem' }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="inherit"
                    component="div"
                    sx={{ fontSize: customFontSize, fontWeight: 'bold', color: textColor }}
                >{`${Math.round(value)}%`}</Typography>
            </Box>
        </Box>
    );
};
