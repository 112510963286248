import React, { useEffect, useState } from 'react';
import {
    IActiveTab,
    TabsSection,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/TabsSection/TabsSection';
import { generatePath, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useQueryGetBoardInitLite } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/boardInitLiteQuery';
import {
    useMutationCreateCard,
    useMutationGetActivityOptions,
    useMutationGetWBSOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useMutationMainCard';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useForm } from 'react-hook-form';
import { isStringHasRealContent, sortAlphabeticalArray } from 'utilitys/helpers/general';
import {
    activityIdMapper,
    assigneeMapper,
    versionMapper,
    wbsMapper,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { useCommentsEditorStatus } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/store/commentsEditorStatusStore';
import { CustomizedButton } from 'components/common';
import { mapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/MainCard.utils';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/ProgramActivityCard.module.scss';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { CardFooter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/CardFooter/CardFooter';
import { ConfirmChangesModal } from 'components/common/confirmChangesModal/confirmChangesModal';
import { isEmpty } from 'lodash';
import { CardHeader } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/CardHeader/CardHeader';
import {
    buildProgramActivityCardPayload,
    tabsArrData,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/ProgramActivityCard.utils';
import { ProgramActivityCardStatusSelect } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/ProgramActivityCardStatusSelect/ProgramActivityCardStatusSelect';
import { SideMenu } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/SideMenu/SideMenu';
import { IFields } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/ProgramActivityCard.types';
import { CardContent } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/CardContent/CardContent';
import { cardsDataQueryKey } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import { TrackedUntrackedAction } from 'components/common/TrackedUntrackedAction/trackedUntrackedAction';
import { IGetCardResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useQueryMainCard';
import { useHashValue } from 'hooks/useHashValue';
import { useCommentsStore } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/comments/store/store';
import { useQueryClient } from 'react-query';
import { useMutationCreateEditComment } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/commentsMutation';

interface IProgramActivityCardProps {
    data: IGetCardResponse;
    refetchCardData: () => void;
    saveCardCallback: (value?: number) => void;
    queryKeys: string[];
}

export const ProgramActivityCard = ({
    data,
    refetchCardData,
    saveCardCallback,
    queryKeys,
}: IProgramActivityCardProps) => {
    const commentData = useCommentsStore((store) => store.commentData);
    const { resetCommentData, setCommentData, setCommentId } = useCommentsStore();
    const { resetCommentsEditorStatus } = useCommentsEditorStatus();

    const queryClient = useQueryClient();

    const hashValue = useHashValue();
    const activeTabByHash = hashValue ? (hashValue as IActiveTab) : 'cardDetails';
    const { pathname, search } = useLocation();
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<IActiveTab>(activeTabByHash);

    useEffect(() => {
        hashValue && setActiveTab(hashValue as IActiveTab);
    }, [hashValue]);

    const history = useHistory();
    const { path } = useRouteMatch();
    const params = useParams();

    const { data: boardInit, isLoading: boardInitLoading } = useQueryGetBoardInitLite();
    const { mutate: mutateWBSOptions, isLoading: wbsOptionsOLoading } = useMutationGetWBSOptions();
    const { mutate: mutateActivityOptions, isLoading: activityOptionsLoading } = useMutationGetActivityOptions();
    const { mutate: mutateCreateCard, isLoading: createCardLoading } = useMutationCreateCard();
    const { mutate: createEditCommentMutate, isLoading: createEditCommentLoading } = useMutationCreateEditComment();

    const { handleSnackBar } = useCustomSnackBar();

    const isLoading =
        boardInitLoading ||
        wbsOptionsOLoading ||
        activityOptionsLoading ||
        createCardLoading ||
        createEditCommentLoading;

    const {
        register,
        control,
        handleSubmit,
        formState: { errors, dirtyFields },
        setValue,
        getValues,
        watch,
        reset,
    } = useForm<IFields>();

    // reset comments on first load - because 'handleModalClose' execute only on Cancel button click
    useEffect(() => {
        resetCommentsEditorStatus();
        resetCommentData();
    }, []);

    // ===============================================================================================================

    useEffect(() => {
        if (boardInit && data) {
            // project
            const currentProject = boardInit.projectContractVersions.find((item) => item.id === data.project.id);

            // contract
            const contractOptions = currentProject?.contracts;
            const currentContract = contractOptions?.find((item) => item.id === data.contract.id);

            // versionName
            const versionOptions = currentContract?.metaDataResponse;
            const currentVersion = versionOptions?.find((item) => item.id === data.metaData.id);

            // WBS List Options
            if (currentVersion?.id) {
                mutateWBSOptions(
                    { versionId: currentVersion.id },
                    {
                        onSuccess: (res) => {
                            const wbsListOptions = sortAlphabeticalArray(res, 'name', 'asc').map(wbsMapper);
                            const currentWbsValue = wbsListOptions?.find(
                                (item) => item.id === data.relatedActivity.wbsId,
                            );
                            setValue('wbsValue', currentWbsValue || null);
                        },
                    },
                );
            }

            if (data.relatedActivity.wbsId) {
                mutateActivityOptions(
                    { wbsId: data.relatedActivity.wbsId },
                    {
                        onSuccess: (res) => {
                            const activityListOptions = sortAlphabeticalArray(res, 'name', 'asc').map(activityIdMapper);
                            const currentActivityId = activityListOptions?.find(
                                (item) => item.value === data.relatedActivity.activityId,
                            );

                            const currentActivityIdFromGetCardData = {
                                id: data.relatedActivity.id,
                                label: data.relatedActivity.activityId,
                                value: data.relatedActivity.activityId,
                            };

                            setValue('activityId', currentActivityId || currentActivityIdFromGetCardData);
                        },
                    },
                );
            }

            reset({
                id: !data.isVirtual ? data.id : null,
                project: currentProject ? mapper(currentProject) : null,
                contract: currentContract ? mapper(currentContract) : null,
                versionName: currentVersion ? versionMapper(currentVersion) : null,
                wbsValue: null,
                activityId: null,
                title: data.title,
                status: { ...data.status, originalStatus: data.originalStatus.name },
                originalStatus: data.originalStatus,
                details: data.details,
                mentionedList: data.mentionedList,
                tagList: data.tagList.map((item) => ({ label: item.name, value: item.name })),
                assignee: assigneeMapper(data.assignee),
                category: mapper(data.category),
                isImportant: data.isImportant,
                startDate: data.startDate,
                endDate: data.endDate,
                externalLinks: data.externalLinks,
                versionId: currentVersion?.id,
                remainingDurationInDays: data.remainingDurationInDays,
                submittedDurationComplete: data.submittedDurationComplete,

                relatedCardIds: null,
                type: 'PROGRAMME_ACTIVITY',

                // dates by activity id
                cardDates: {
                    baselinePlannedStartDate: moment(data.baselinePlannedStartDate).format(
                        constants.formats.date.default,
                    ),
                    baselinePlannedEndDate: moment(data.baselinePlannedEndDate).format(constants.formats.date.default),
                    plannedStartDate: moment(data.relatedActivity?.plannedStartDate).format(
                        constants.formats.date.default,
                    ),
                    plannedFinishDate: moment(data.relatedActivity?.plannedFinishDate).format(
                        constants.formats.date.default,
                    ),
                    calculatedStartDate: moment(data.relatedActivity?.calculatedStartDate).format(
                        constants.formats.date.default,
                    ),
                    calculatedFinishDate: moment(data.relatedActivity?.calculatedFinishDate).format(
                        constants.formats.date.default,
                    ),
                },
            });
        }
    }, [boardInit, data]);

    const handleCommentData = () => {
        if (!isStringHasRealContent(commentData.comment)) {
            createEditCommentMutate(commentData, {
                onSuccess: () => {
                    return Promise.all([queryClient.invalidateQueries('getCard')]).then(() => {
                        resetCommentsEditorStatus();
                        setCommentData({ ...commentData, cardId: data.id, isVirtual: data.isVirtual, comment: '' });
                        setCommentId(false);
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
            });
        }
    };

    const handleSave = (data) => {
        const payload = buildProgramActivityCardPayload(data);

        mutateCreateCard(payload, {
            onSuccess: (res) => {
                handleSnackBar('Card Successfully Updated', 'success');

                if (watch('id')) {
                    refetchCardData();
                    reset(getValues());

                    handleCommentData();
                } else {
                    if (!isStringHasRealContent(commentData.comment)) {
                        createEditCommentMutate(
                            { ...commentData, cardId: res.id, isVirtual: false },
                            {
                                onSuccess: () => {
                                    resetCommentsEditorStatus();
                                    setCommentData({
                                        ...commentData,
                                        cardId: res.id,
                                        isVirtual: false,
                                        comment: '',
                                    });
                                    setCommentId(false);

                                    const link = `${generatePath(path, { ...params, cardId: res.id })}${search}`;
                                    history.push(link);
                                },
                                onError: (error) => {
                                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                                },
                            },
                        );
                    } else {
                        const link = `${generatePath(path, { ...params, cardId: res.id })}${search}`;
                        history.push(link);
                    }
                }
                saveCardCallback(res.id);
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
            },
            onSettled: () => {
                if (showConfirmModal) {
                    handleModalClose();
                }
            },
        });
    };

    const isStateEmpty = isEmpty(getValues());
    const isCardDirty = !isEmpty(dirtyFields) || !isStringHasRealContent(commentData.comment);

    const handleCardClose = () => {
        if (isCardDirty) {
            setShowConfirmModal(true);
        } else {
            handleModalClose();
        }
    };

    const handleConfirmModalSave = () => {
        handleSubmit(handleSave)();
    };

    //==================================== handleModalClose ============================================================
    const handleModalClose = () => {
        resetCommentsEditorStatus();
        resetCommentData();

        const pathWithoutParams = pathname.slice(0, pathname.lastIndexOf('/'));
        setShowConfirmModal(false);
        history.push({
            pathname: pathWithoutParams,
            search: search,
        });
    };
    //==================================================================================================================

    return (
        <>
            <form onSubmit={handleSubmit(handleSave)} className={classes.programActivityCardContainer}>
                {isLoading && <OverlayWithSpinner />}

                {!isStateEmpty && (
                    <>
                        <SideMenu
                            title={data.title}
                            taskId={data.taskId}
                            taskVersionHashCode={data.taskVersionHashCode}
                            originalStatus={data.originalStatus}
                            status={watch('status')}
                            setValue={setValue}
                            category={data.category.name}
                        />

                        <CardHeader control={control} />
                        <div className={classes.tabsWithStatusWrapper}>
                            <TabsSection tabs={tabsArrData} activeTab={activeTab} setActiveTab={setActiveTab} />
                            <div className={classes.starWrapper}>
                                <TrackedUntrackedAction
                                    isTracked={data.isTracked}
                                    taskVersionHashCode={data.taskVersionHashCode}
                                    invalidateQueriesKey={['getCard', cardsDataQueryKey]}
                                />
                                <ProgramActivityCardStatusSelect
                                    control={control}
                                    category={watch('category')}
                                    originalStatus={watch('originalStatus')}
                                />
                            </div>
                        </div>

                        <CardContent
                            activeTab={activeTab}
                            boardInit={boardInit}
                            setValue={setValue}
                            watch={watch}
                            control={control}
                            errors={errors}
                            data={data}
                            queryKeys={queryKeys}
                            register={register}
                        />

                        <CardFooter>
                            <CustomizedButton
                                size={'large'}
                                color={'secondary'}
                                onClick={handleCardClose}
                                id={'cardModalCloseOrCancelButton'}
                            >
                                Close
                            </CustomizedButton>
                            <CustomizedButton
                                type={'submit'}
                                size={'large'}
                                color={'primary'}
                                id={'cardModalSaveButton'}
                                disabled={!isCardDirty}
                            >
                                Save
                            </CustomizedButton>
                        </CardFooter>
                    </>
                )}
            </form>

            <ConfirmChangesModal
                showModal={showConfirmModal}
                onHideModal={() => setShowConfirmModal(false)}
                handleCloseWithoutSave={handleModalClose}
                handleSave={handleConfirmModalSave}
                isLoading={createCardLoading}
            >
                You made changes to this card. Would you like to save it?
            </ConfirmChangesModal>
        </>
    );
};
