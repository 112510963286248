import React, { useCallback, useEffect, useMemo } from 'react';
import ValidationConfigSlider from 'components/Dashboards/ExcellenceApps/LogicChecker/ValidationConfigSlider';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import classes from 'components/Dashboards/ExcellenceApps/LogicChecker/LogicChecker.module.scss';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import {
    getCheck,
    getValidationColumns,
    initialStateHelper,
    isWholeProject,
} from 'components/Dashboards/ExcellenceApps/LogicChecker/helper';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { useParams, Link, useLocation } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import {
    useMutationSetCheckMandatory,
    useQueryGetLogicCheckerCategoryResults,
    useQueryGetLogicCheckerConfiguration,
    useQueryGetValidationTypeDrillDown,
} from 'components/Dashboards/ExcellenceApps/LogicChecker/queries/useQueryLogicChecker';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import SwitchComponent from 'components/common/Switch/Switch';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useValidationStore } from 'components/Dashboards/ExcellenceApps/LogicChecker/store/validationStore';
import useUserHook from 'hooks/useUserHook';
import { CardWidget } from 'components/Dashboards/ExcellenceApps/LogicChecker/components/cardWidget/cardWidget';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { useLogicCheckerFilterHook } from 'components/Dashboards/ExcellenceApps/LogicChecker/hooks/useLogicCheckerFilterHook';

export const ValidationTable = () => {
    const { pathname, search } = useLocation();
    const { ability } = useUserHook();
    const { handleSnackBar } = useCustomSnackBar();
    const { validation, update } = useValidationStore();
    const { id: versionId, category, check } = useParams<{ id: string; category: string; check: string }>();
    const { idList, versionList } = useHashmapConverter(versionId);
    const projectId = versionList[0]?.projectId;
    const contractId = versionList[0]?.contractId;
    const fileMetaDataId = idList.length > 0 ? parseInt(idList.join(',')) : undefined;
    const filters = useLogicCheckerFilterHook();
    const { data: logicCheckerData, isLoading: logicCheckerDataLoading } = useQueryGetLogicCheckerCategoryResults({
        fileMetaDataId,
        filter: {
            filters,
        },
    });

    const { data: configuration, refetch: refetchConfig } = useQueryGetLogicCheckerConfiguration({
        contractId,
    });

    const enableManage = ability.can('view', 'LogicCheckerManager') || ability.can('all', 'Admin');

    const mutation = useMutationSetCheckMandatory();
    const parsedCategory = category.replace('_', '/');
    const parsedCheck = check ? check.replace('_', '/') : '';
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId: idList[0] });

    const pickedCheck = getCheck({
        categoriesSummaryRows: logicCheckerData?.categoriesSummaryRows,
        parsedCategory,
        parsedCheck,
    });

    useEffect(() => {
        if (configuration) {
            const validation = configuration.find((item) => item.validationName === parsedCheck);
            const prevValue = validation?.thresholds[0].upper_limit_value || 0;
            update({ validation, prevValue });
        }
        return () => {
            update({ validation: undefined, prevValue: undefined });
        };
    }, [configuration, parsedCheck]);

    const { data: validationTypeDrillDown, isLoading: validationTypeDrillDownLoading } =
        useQueryGetValidationTypeDrillDown({
            fileMetaDataId,
            componentId: pickedCheck?.validationId,
            filter: {
                filters: filters,
            },
        });

    const toggleSwitch = useCallback(() => {
        if (pickedCheck?.validationId && validation && fileMetaDataId) {
            mutation.mutate(
                { checkId: pickedCheck?.validationId, mandatory: !validation.mandatory, metaDataId: fileMetaDataId },
                {
                    onSuccess: () => {
                        refetchConfig().then(() => {
                            handleSnackBar('Config saved successfully.', 'success');
                        });
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong saving the config.', 'error');
                    },
                },
            );
        }
    }, [pickedCheck, validation]);

    const generatedCols = useMemo(() => {
        return getValidationColumns({ validationTypeDrillDown, validation, check });
    }, [validationTypeDrillDown, check, validation]);

    const generatedData = useMemo(() => {
        return validationTypeDrillDown?.rows.filter(isWholeProject) || [];
    }, [validationTypeDrillDown]);

    const showLoader = useMemo(() => {
        return logicCheckerDataLoading || validationTypeDrillDownLoading;
    }, [logicCheckerDataLoading, validationTypeDrillDownLoading]);

    const initialState = useMemo(() => {
        return initialStateHelper({
            generatedCols,
            parsedCheck,
        });
    }, [generatedCols]);

    const versionName = `${versionList[0]?.versionName} (${moment(versionList[0]?.versionDate).format(
        constants.formats.date.default,
    )})`;

    const cardLink = useMemo(() => {
        return (
            <Link
                to={{
                    pathname: `${pathname}/card-${pickedCheck?.associatedCard?.cardId || 'newCard'}`,
                    search: search,
                }}
            >
                {parsedCheck}
            </Link>
        );
    }, [pickedCheck, pathname]);

    const titleWithLink = (
        <>
            {parsedCategory} | {cardLink}
        </>
    );

    const titleAsString = `${parsedCategory} | ${parsedCheck}`;

    return (
        <WidgetWithTitle
            title={titleWithLink}
            titleComponents={[
                <div className={classes.cardWidgetWrapper} key={'CardWidget'}>
                    <CardWidget
                        projectId={projectId}
                        contractId={contractId}
                        versionName={versionName}
                        cardId={pickedCheck?.associatedCard.cardId}
                        status={pickedCheck?.associatedCard.status}
                        assignee={pickedCheck?.associatedCard.assignee}
                        title={parsedCheck}
                        metaDataId={fileMetaDataId}
                        checkId={pickedCheck?.validationId}
                    />
                </div>,
                validation && (
                    <div className={classes.switchWrapper}>
                        <SwitchComponent
                            disabled={!enableManage}
                            checked={validation?.mandatory}
                            onChange={toggleSwitch}
                        />
                        <div className={classes.label}>Include in overall score</div>
                    </div>
                ),
                <React.Fragment key={'ValidationConfigSlider'}>
                    {validation && fileMetaDataId && (
                        <ValidationConfigSlider disabled={!enableManage} fileMetaDataId={fileMetaDataId} />
                    )}
                </React.Fragment>,
                <ExportDropdown key={'export'} title={titleAsString} subTitle={subTitle} />,
            ]}
        >
            <div className={classes.tableContainer}>
                <div className={classes.validationDescription}>
                    <h3>Validation overview</h3>
                    <p>{validationTypeDrillDown?.validationDescription}</p>
                </div>
                <MainTable
                    columns={generatedCols}
                    data={generatedData}
                    initialState={initialState}
                    isLoading={showLoader}
                />
            </div>
        </WidgetWithTitle>
    );
};
