import React from 'react';
import Select, { components, GroupBase, OptionProps, Props, SelectInstance, ValueContainerProps } from 'react-select';
import { CheckboxComponent } from 'components/common/CheckboxComponent/checkboxComponent';
import SwitchComponent from 'components/common/Switch/Switch';
import classes from './myCustomSelect.module.scss';
import { MenuListProps } from 'react-select/dist/declarations/src/components/Menu';
import { useMyCustomSelectorStatus } from 'components/common/MyCustomSelect/zustand_store/myCustomSelectStore';
import { SelectStyles } from 'components/common/MyCustomSelect/myCustomSelect.style';
import Icon from 'components/common/Icons/icon';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import { AssigneeAvatar } from 'components/common/AssigneeAvatar/AssigneeAvatar';
import type {} from 'react-select/base';
import { OnChangeValue } from 'react-select/dist/declarations/src/types';
import { IAssignee } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';

declare module 'react-select/base' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
        isError?: boolean | null;
    }
}

export const MyCustomSelect = React.forwardRef(
    <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
        props: Props<Option, IsMulti, Group>,
        ref: React.LegacyRef<SelectInstance<Option, IsMulti, Group>> | undefined,
    ) => {
        return <Select styles={SelectStyles} {...props} ref={ref} />;
    },
);

export const OptionsWithExclude = <T extends { label: string; value: { name: string; isExclude: boolean } }>(
    props: OptionProps<T>,
) => {
    const { value, label } = props.data;
    const { setValue, getValue } = props;

    const selectedOptions = getValue();

    const handleExclude = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (selectedOptions.length > 2) return;

        setValue(
            [
                ...selectedOptions,
                {
                    label: `Exclude:${label}`,
                    value: {
                        name: value.name,
                        isExclude: true,
                    },
                },
            ] as T[],
            'select-option',
        );
    };

    return (
        <>
            {!selectedOptions.find((item) => item.value.name === value.name) && (
                <components.Option {...props}>
                    <div className={classes.labelWithExcludeWrapper}>
                        {label}
                        <div className={classes.hoverBox}>
                            <div data-testid={'include-text'} className={classes.includeExcludeTextHover}>
                                Include
                            </div>
                            <div
                                data-testid={'exclude-text'}
                                className={classes.includeExcludeTextHover}
                                onClick={(e) => handleExclude(e)}
                            >
                                Exclude
                            </div>
                        </div>
                    </div>
                </components.Option>
            )}
        </>
    );
};

export const OptionsWithAssignee = <T,>(props: OptionProps<T> & { data: { label: string; assignee: IAssignee } }) => {
    const { assignee, label } = props.data;

    return (
        <components.Option {...props}>
            <div className={classes.labelWithAssigneeWrapper}>
                <AssigneeAvatar assignee={assignee} />
                {label}
            </div>
        </components.Option>
    );
};

export const InputOptionsWithIcon = <T,>(
    props: OptionProps<T> & {
        data: {
            label: string;
            icon?: string;
            muiIcon?: string;
        };
    },
) => {
    const { icon, muiIcon, label } = props.data;

    const iconElement = Boolean(icon || muiIcon) ? (
        icon ? (
            <Icon name={icon} size="1.6rem" />
        ) : (
            <MuiIcon icon={muiIcon} />
        )
    ) : null;

    return (
        <components.Option {...props}>
            {iconElement ? (
                <div className={classes.labelWithIconWrapper}>
                    <div className={classes.iconWrapper}>{iconElement}</div>
                    {label}
                </div>
            ) : (
                label
            )}
        </components.Option>
    );
};

export const ValueContainer = <T extends { label: string }>({
    children,
    getValue,
    options,
    selectProps,
    ...rest
}: ValueContainerProps<T>) => {
    const value = getValue();

    let title = '';
    if (value.length === 1) title = value[0].label;
    if (value.length === 2) title = value.map((item) => item.label).join(', ');
    if (value.length > 2 && value.length < options.length)
        title = `${value[0].label}, ${value[1].label} & ${value.length - 2} more`;
    if (value.length === options.length && options.length > 0) title = `All (${options.length})`;

    const defaultChildren = React.Children.toArray(children).filter((child: any) =>
        [components.Input, components.Placeholder].includes(child.type),
    );

    return (
        <components.ValueContainer {...rest} getValue={getValue} options={options} selectProps={selectProps}>
            {[title, ...defaultChildren]}
        </components.ValueContainer>
    );
};

export const InputOption = <T,>({
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}: OptionProps<T>) => {
    const style = {
        alignItems: 'center',
        backgroundColor: 'transparent',
        color: 'inherit',
        display: 'flex ',
        gap: '0.8rem',
    };

    // prop assignment
    const props = {
        ...innerProps,
        style,
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <CheckboxComponent checked={isSelected} />
            {children}
        </components.Option>
    );
};

export const MenuList = <T,>({ children, ...rest }: MenuListProps<T>) => {
    const { showOnlyAliasStatus, update } = useMyCustomSelectorStatus();

    const toggleSwitch = () => {
        update({ showOnlyAliasStatus: !showOnlyAliasStatus });
    };

    const filteredChildren = React.Children.toArray(children).filter((child: any) => child.props.data?.alias);

    const filteredChildrenFinal =
        filteredChildren.length === 0 ? (
            <components.NoOptionsMessage {...rest}>No options</components.NoOptionsMessage>
        ) : (
            filteredChildren
        );

    return (
        <div>
            <div className={classes.menuListSwitchContainer}>
                <span>Show only activity codes with aliases</span>
                <SwitchComponent checked={showOnlyAliasStatus} onChange={toggleSwitch} />
            </div>
            <components.MenuList {...rest}>
                {showOnlyAliasStatus ? filteredChildrenFinal : children}
            </components.MenuList>
        </div>
    );
};

export const MenuListSelectAll = <
    Option,
    IsMulti extends boolean = boolean,
    Group extends GroupBase<Option> = GroupBase<Option>,
>({
    children,
    setValue,
    options,
    getValue,
    ...rest
}: MenuListProps<Option, IsMulti, Group>) => {
    const allSelected = options.length === getValue().length;
    const selectDeselectTitle = !allSelected ? 'Select all' : 'De-select all';
    const handleSelectDeSelectAll = () => {
        !allSelected
            ? setValue(options as OnChangeValue<Option, IsMulti>, 'select-option')
            : setValue([] as unknown as OnChangeValue<Option, IsMulti>, 'select-option');
    };

    return (
        <div>
            <div className={classes.menuListSwitchContainer}>
                <span className={classes.selectDeselectTitle} onClick={handleSelectDeSelectAll}>
                    {selectDeselectTitle}
                </span>
            </div>
            <components.MenuList setValue={setValue} options={options} getValue={getValue} {...rest}>
                {children}
            </components.MenuList>
        </div>
    );
};
