import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/programCriticalAnalysis.module.scss';
import CriticalAnalysisHeatMap from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisHeatMap/criticalAnalysisHeatMap';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { LoaderContainer, MyCustomSelect } from 'components/common';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import NoData from 'components/common/NoData/noData';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import {
    useQueryHeatMapActivityDuration,
    useQueryHeatMapActivityResources,
    useQueryHeatMapActivityStart,
    useQueryHeatMapActivityStartOrFinish,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/queries/criticalAnalysisQuery';

import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { IComponentProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useQueryState } from 'hooks/useQueryState';
import {
    defaultHeatMapOption,
    heatMapInitialData,
    heatMapOptions,
    HeatMapOptionsValue,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/utils';
import {
    TableDateRangeFilter,
    TDateRangeOption,
} from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter';
import { List } from 'components/common/ListWrapper/list';
import {
    resolutionOptions,
    serializeHeatmap,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/CriticalAnalysisHeatMapComponent/RenderCriticalAnalysisHeatMap.utils';
import moment from 'moment-timezone';
import { useProgramCriticalAnalysisStore } from '../../store/programCriticalAnalysisStore';

const RenderCriticalAnalysisHeatMap = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    localFilters,
    filter,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IComponentProps) => {
    const [dateRange, setDateRange] = useState<{ startDate: number | null; endDate: number | null }>(
        widgetId && filter && filter.filters.dateRange
            ? filter.filters.dateRange
            : {
                  startDate: null,
                  endDate: null,
              },
    );
    const [dateRangeOption, setDateRangeOption] = useState<TDateRangeOption>('wholeProject');
    const [resolutionValue, setResolutionValue] = useState<ISelectOption<boolean>>(
        widgetId && filter && filter.filters.resolutionValue
            ? filter.filters.resolutionValue
            : {
                  value: false,
                  label: 'Month',
              },
    );

    const { update } = useProgramCriticalAnalysisStore();

    useEffect(() => {
        if (latestVersionId && !widgetId && !filter) {
            setDateRange({
                startDate: null,
                endDate: null,
            });
            setDateRangeOption('wholeProject');
        }
    }, [latestVersionId, widgetId, filter]);

    const idList = useMemo(() => (latestVersionId ? [latestVersionId] : []), [latestVersionId]);
    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters.mainFilters?.filters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes,
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                  }),

        [filter, globalFilterData],
    );

    const payload = useMemo(() => {
        return {
            id: idList,
            filter: {
                filters,
            },
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            isWeekResolution: resolutionValue.value,
        };
    }, [idList, filters, dateRange, resolutionValue]);

    const { generateFilters: formatedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: widgetId && filter ? { filters: filter?.filters?.mainFilters || [] } : undefined,
        latestVersionId,
    });
    const { data: heatMapActivityStarts, isLoading: heatMapActivityStartLoading } =
        useQueryHeatMapActivityStart(payload);
    const { data: heatMapActivityDuration, isLoading: heatMapActivityDurationLoading } =
        useQueryHeatMapActivityDuration(payload);
    const { data: heatMapActivityStartOrFinish, isLoading: heatMapActivityStartOrFinishLoading } =
        useQueryHeatMapActivityStartOrFinish(payload);
    const { data: heatMapActivityResources, isLoading: heatMapActivityResourcesLoading } =
        useQueryHeatMapActivityResources(payload);

    const isLoading =
        heatMapActivityStartLoading &&
        heatMapActivityDurationLoading &&
        heatMapActivityStartOrFinishLoading &&
        heatMapActivityResourcesLoading;

    const heatmap = useMemo(() => {
        if (
            heatMapActivityStarts &&
            heatMapActivityDuration &&
            heatMapActivityStartOrFinish &&
            heatMapActivityResources
        ) {
            return {
                heatMapActivityStarts,
                heatMapActivityDuration,
                heatMapActivityStartOrFinish,
                heatMapActivityResources,
            };
        }
        return null;
    }, [heatMapActivityStarts, heatMapActivityDuration, heatMapActivityStartOrFinish, heatMapActivityResources]);

    const [riskDensityBy, setRiskDensityBy] = useQueryState('riskDensityBy');

    const selectByInitialValue: string | undefined = useMemo(() => {
        return widgetId && localFilters?.selectBy ? localFilters.selectBy.value : riskDensityBy;
    }, [widgetId, localFilters, riskDensityBy]);

    const [selectBy, setSelectBy] = useState<ISelectOption<HeatMapOptionsValue> | null>(null);
    useEffect(() => {
        if (!selectByInitialValue && !widgetId) {
            setRiskDensityBy(defaultHeatMapOption.value);
            setSelectBy(defaultHeatMapOption);
        } else {
            setSelectBy(heatMapOptions.find((filter) => filter.value === selectByInitialValue) || null);
        }
    }, [selectByInitialValue, widgetId]);

    const selectedHeatmap = useMemo(() => {
        if (selectBy && heatmap) {
            return heatmap[selectBy.value];
        }
        return heatMapInitialData;
    }, [selectBy, heatmap]);

    const {
        info,
        heatMapItemResponseList: data,
        endProjectDate: endProjectDateFromBE,
        startProjectDate: startProjectDateFromBE,
    } = selectedHeatmap;

    const [endProjectDate, setEndProjectDate] = useState<number>(0);
    const [startProjectDate, setStartProjectDate] = useState<number>(0);

    useEffect(() => {
        if (heatmap) {
            setEndProjectDate(endProjectDateFromBE);
            setStartProjectDate(startProjectDateFromBE);
        }
    }, [heatmap]);

    const title = 'Risk Density by';
    const serialized = useMemo(() => {
        return serializeHeatmap(data, resolutionValue.value);
    }, [data, resolutionValue]);

    const setDateOnDelayDriver = useCallback(
        (value: string) => {
            const date = serialized.find((item) => item.id === value);
            if (date) {
                const m = moment(date.date);
                update({
                    dateFilter: {
                        value: m.format('yyyyMM'),
                        label: m.format('MMM yyyy'),
                    },
                });
            }
        },
        [serialized],
    );

    const handleChange = (value: ISelectOption<HeatMapOptionsValue> | null) => {
        if (value) {
            setRiskDensityBy(value.value);
        }
    };

    const id = 'RiskDensityByActivityStarts';
    const componentKey = 'riskHeatMap';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;

    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);

    const handleDateRangeSelection = (value: { startDate: number | null; endDate: number | null }) => {
        setDateRange(value);
    };

    const handleResolutionFilter = (value: ISelectOption<boolean>) => {
        setResolutionValue(value);
    };

    const pinBoardFilterData = useMemo(() => {
        return {
            mainFilters: filters,
            dateRange: dateRange,
            resolutionValue: resolutionValue,
        };
    }, [filters, dateRange, resolutionValue]);

    return (
        <ErrorBoundary>
            <WidgetWithTitle
                id={id}
                title={title}
                tooltip={info}
                titleComponents={[
                    <div className={classes.titleComponents} key={'filterCriticalAnalysisHeatMap'}>
                        {widgetId ? (
                            <h3>{selectBy?.label}</h3>
                        ) : (
                            <MyCustomSelect<ISelectOption<HeatMapOptionsValue>>
                                id={'filterCriticalAnalysisHeatMap'}
                                value={selectBy}
                                options={heatMapOptions}
                                onChange={handleChange}
                                key={'filterCriticalAnalysisHeatMap-Key'}
                            />
                        )}
                        <div className={classes.dateRangeWrapper}>
                            <div>Date Range</div>
                            <TableDateRangeFilter
                                onChange={handleDateRangeSelection}
                                startProjectDate={startProjectDate}
                                endProjectDate={endProjectDate}
                                value={dateRange}
                                isDisabled={!!(widgetId && filter)}
                                dateRangeOption={dateRangeOption}
                                setDateRangeOption={setDateRangeOption}
                            />
                        </div>
                    </div>,
                    externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                    <AddWidget
                        key={'AddWidget'}
                        title={title}
                        componentKey={componentKey}
                        projectId={projectId}
                        contractId={contractId}
                        widgetId={widgetId}
                        route={route}
                        filters={pinBoardFilterData}
                        localFilters={{ selectBy }}
                    />,
                    <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                    <ExportDropdown key={'export'} title={title} subTitle={subTitle} />,
                ]}
                titleFilters={[
                    editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,

                    widgetId && formatedFilter.length > 0 ? (
                        <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formatedFilter} />
                    ) : null,
                ]}
            >
                {!isLoading && serialized?.length > 0 && (
                    <>
                        <div className={classes.topSection}>
                            <div>{!widgetId && <span>Click any month to filter the activities table below.</span>}</div>
                            <div className={classes.resolutionWrapper}>
                                <div className={classes.title}>Resolution:</div>
                                <List<boolean>
                                    onChange={handleResolutionFilter}
                                    options={resolutionOptions}
                                    value={resolutionValue}
                                />
                            </div>
                        </div>
                        <CriticalAnalysisHeatMap data={serialized} handleClick={setDateOnDelayDriver} />
                    </>
                )}
                {isLoading && !data && (
                    <LoaderContainer>
                        <OverlayWithSpinner />
                    </LoaderContainer>
                )}
                {!isLoading && (!serialized || serialized.length === 0) && <NoData />}
            </WidgetWithTitle>
        </ErrorBoundary>
    );
};

export default RenderCriticalAnalysisHeatMap;
