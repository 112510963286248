import React from 'react';
import classes from './AddSubProgramModal.module.scss';
import { CustomizedButton, CustomizedFormErrorMessage, CustomizedModalBase } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { useProjectStore } from 'components/common/TreeProgramFilter/store/treeHeaderStore';

interface IAddSubProgramModalForm {
    name: string;
}

export const AddSubProgramModal = ({ showModal, onHideModal, handleSave, isLoadingCreateContract }) => {
    const { selectedProject } = useProjectStore();
    const {
        handleSubmit,
        setValue,
        control,
        formState: { errors },
        reset,
    } = useForm<IAddSubProgramModalForm>({
        defaultValues: {
            name: '',
        },
    });

    const handleSaveUseForm = (value) => {
        handleSave(value.name);
        closeModal();
    };

    const closeModal = () => {
        reset();
        onHideModal();
    };

    return (
        <CustomizedModalBase isModalOpen={showModal} handleModalClose={closeModal}>
            <div className={classes.addSubProgramModalContainer}>
                <form onSubmit={handleSubmit(handleSaveUseForm)} autoComplete="off">
                    <div className={classes.title}>Add Sub-Program</div>
                    <div className={classes.inputTitle}>Project: {selectedProject.project?.title}</div>
                    <div className={classes.inputTitle}>Name *</div>

                    <div className={classes.inputWrapper}>
                        <Controller
                            render={({ field }) => (
                                <TextSearchInput
                                    {...field}
                                    placeholder={'Add text...'}
                                    isSearch={false}
                                    maxLength={30}
                                    handleClear={() => {
                                        setValue('name', '');
                                    }}
                                />
                            )}
                            name={`name`}
                            control={control}
                            rules={{
                                required: 'Name is Required',
                                minLength: {
                                    value: 2,
                                    message: 'Name is too short',
                                },
                                pattern: {
                                    value: /^(?!\s+$).*/,
                                    message: 'Name cannot be empty',
                                },
                                validate: {
                                    notValidName: (value) =>
                                        value.toLowerCase() !== 'all' || '"All" is not a valid sub-program name.',
                                },
                            }}
                        />
                    </div>

                    {errors.name && <CustomizedFormErrorMessage text={errors.name.message} />}

                    <div className={classes.footer}>
                        <div className={classes.container}>
                            <CustomizedButton size={'large'} color={'secondary'} onClick={closeModal}>
                                Cancel
                            </CustomizedButton>

                            <CustomizedButton
                                type="submit"
                                size={'large'}
                                color={'primary'}
                                isLoading={isLoadingCreateContract}
                                disabled={isLoadingCreateContract}
                            >
                                Save
                            </CustomizedButton>
                        </div>
                    </div>
                </form>
            </div>
        </CustomizedModalBase>
    );
};
