export type ColumnCommonConfig = keyof typeof columnsCommonConfig;
interface IColumnCommonConfigItem {
    id: string;
    width: number;
    fixedSize?: boolean;
    isDisableReorder?: boolean;
    isDisableVisibility?: boolean;
}

type ColumnsCommonConfigItems = {
    [key in ColumnCommonConfig]: IColumnCommonConfigItem;
};

export const columnsCommonConfig = {
    clusterName: {
        id: 'clusterName',
        width: 300,
        size: 300,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
        disableGroupBy: false,
        isDisableReorder: true,
        isDisableVisibility: true,
    },
    activity: {
        id: 'activity',
        width: 274,
        size: 274,
        isDisableReorder: true,
        isDisableVisibility: true,
    },
    status: {
        id: 'status',
        width: 215,
        size: 215,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
        isDisableReorder: true,
        isDisableVisibility: true,
    },
    projectNameSubProgram: {
        id: 'projectNameSubProgram',
        width: 530,
        size: 530,
        isDisableReorder: true,
        isDisableVisibility: true,
    },
    activityType: {
        id: 'activityType',
        width: 120,
        size: 120,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    recommendation: {
        id: 'recommendation',
        width: 460,
        size: 460,
        isDisableReorder: true,
        isDisableVisibility: true,
    },
    projectName: {
        id: 'projectName',
        width: 130,
        size: 130,
    },
    contractName: {
        id: 'contractName',
        width: 130,
        size: 130,
    },
    versionName: {
        id: 'versionName',
        width: 220,
        size: 220,
    },
    forecastDuration: {
        id: 'forecastDuration',
        width: 140,
        size: 140,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    actualDuration: {
        id: 'actualDuration',
        width: 90,
        size: 90,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    remainingDuration: {
        id: 'remainingDuration',
        width: 90,
        size: 90,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    forecastOverrun: {
        id: 'forecastOverrun',
        width: 120,
        size: 120,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    startDate: {
        id: 'startDate',
        width: 140,
        size: 140,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    finishDate: {
        id: 'finishDate',
        width: 140,
        size: 140,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    versionDate: {
        id: 'versionDate',
        width: 140,
        size: 140,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    duration: {
        id: 'duration',
        width: 120,
        size: 120,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    progress: {
        id: 'progress',
        width: 100,
        size: 100,
    },
    risk: {
        id: 'risk',
        width: 100,
        size: 100,
    },
    quality: {
        id: 'quality',
        width: 100,
        size: 100,
    },
    submittedCompletionDate: {
        id: 'submittedCompletionDate',
        width: 160,
        size: 160,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    criticalityAdherence: {
        id: 'criticalityAdherence',
        width: 140,
        size: 140,
    },
    milestoneAdherence: {
        id: 'milestoneAdherence',
        width: 140,
        size: 140,
    },
    latestVersionCategory: {
        id: 'latestVersionCategory',
        width: 130,
        size: 130,
    },
    latestVersionSeverity: {
        id: 'latestVersionSeverity',
        width: 160,
        size: 160,
    },
    submittedDurationComplete: {
        id: 'submittedDurationComplete',
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
        width: 100,
        size: 100,
    },
    narrative: {
        id: 'narrative',
        width: 370,
        size: 370,
    },
    successorsInFragnet: {
        id: 'successorsInFragnet',
        width: 120,
        size: 120,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    totalSuccessors: {
        id: 'totalSuccessors',
        width: 120,
        size: 120,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    float: {
        id: 'float',
        width: 120,
        size: 120,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    criticalPath: {
        id: 'criticalPath',
        width: 90,
        size: 90,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    criticalityScore: {
        id: 'criticalityScore',
        width: 120,
        size: 120,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    predecessors: {
        id: 'predecessors',
        width: 90,
        size: 90,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    successors: {
        id: 'successors',
        width: 90,
        size: 90,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    details: {
        id: 'details',
        width: 500,
        size: 500,
    },
    plannedDuration: {
        id: 'plannedDuration',
        width: 140,
        size: 140,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    completedDuration: {
        id: 'completedDuration',
        width: 90,
        size: 90,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    relationshipType: {
        id: 'relationshipType',
        width: 110,
        size: 110,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    successorName: {
        id: 'successorName',
        width: 190,
        size: 190,
    },
    plannedStartDate: {
        id: 'plannedStartDate',
        width: 190,
        size: 190,
    },
    plannedFinishDate: {
        id: 'plannedFinishDate',
        width: 190,
        size: 190,
    },
    baselineStartDate: {
        id: 'baselineStartDate',
        width: 190,
        size: 190,
    },
    baselineFinishDate: {
        id: 'baselineFinishDate',
        width: 190,
        size: 190,
    },
    actualStartDate: {
        id: 'actualStartDate',
        width: 190,
        size: 190,
    },
    actualFinishDate: {
        id: 'actualFinishDate',
        width: 190,
        size: 190,
    },
    currentStartDate: {
        id: 'currentStartDate',
        width: 190,
        size: 190,
    },
    currentFinishDate: {
        id: 'currentFinishDate',
        width: 190,
        size: 190,
    },
    durationOverrun: {
        id: 'durationOverrun',
        width: 90,
        size: 90,
    },
    overrunDays: {
        id: 'overrunDays',
        width: 90,
        size: 90,
    },
    overrunPercent: {
        id: 'overrunPercent',
        width: 90,
        size: 90,
    },
    numOfPredecessors: {
        id: 'numOfPredecessors',
        width: 130,
        size: 130,
    },
    numOfSuccessors: {
        id: 'numOfSuccessors',
        width: 130,
        size: 130,
    },
    projectWeekStart: {
        id: 'projectWeekStart',
        width: 90,
        size: 90,
    },
    projectWeekFinish: {
        id: 'projectWeekFinish',
        width: 90,
        size: 90,
    },
    projectMonthStart: {
        id: 'projectMonthStart',
        width: 90,
        size: 90,
    },
    projectMonthFinish: {
        id: 'projectMonthFinish',
        width: 90,
        size: 90,
    },
    lateStart: {
        id: 'lateStart',
        width: 90,
        size: 90,
    },
    lateFinish: {
        id: 'lateFinish',
        width: 90,
        size: 90,
    },
    parentName: {
        id: 'parentName',
        width: 370,
        size: 370,
    },
    grandParentName: {
        id: 'grandParentName',
        width: 370,
        size: 370,
    },
    rowOptions: {
        id: 'rowOptions',
        accessor: 'rowOptions',
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
        Filter: false,
        disableSortBy: true,
        size: 150,
    },
    lastSubmittedStatus: {
        id: 'lastSubmittedStatus',
        width: 120,
        size: 120,
    },
    originalStatus: {
        id: 'originalStatus',
        width: 120,
        size: 120,
    },
    tagList: {
        id: 'tagList',
        width: 400,
        size: 400,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
    externalRelation: {
        id: 'externalRelation',
        size: 150,
    },
    previousVersionStartDate: {
        id: 'previousVersionStartDate',
        width: 170,
        size: 170,
    },
    previousVersionFinishDate: {
        id: 'previousVersionFinishDate',
        width: 170,
        size: 170,
    },
    previousVersionStartVariance: {
        id: 'previousVersionStartVariance',
        size: 150,
    },
    previousVersionFinishVariance: {
        id: 'previousVersionFinishVariance',
        size: 150,
    },
    baselineStartVariance: {
        id: 'baselineStartVariance',
        width: 170,
        size: 170,
    },
    baselineFinishVariance: {
        id: 'baselineFinishVariance',
        width: 170,
        size: 170,
    },
    baselineDuration: {
        id: 'baselineDuration',
        size: 170,
    },
    programTags: {
        id: 'programTags',
        width: 250,
        size: 250,
        fixedSize: true,
        meta: {
            isFixedSize: true,
        },
    },
};
