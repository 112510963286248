import { ISelectedProject } from 'components/common/TreeProgramFilter/store/treeHeaderStore.types';

export enum FileExtensions {
    xer = 'xer',
    mpp = 'mpp',
    xlsx = 'xlsx',
}

export interface Version {
    versionId: string;
    versionName: string;
    versionDate: string;
    isBaseline: boolean;
    download: boolean;
}

export interface PlfItem {
    fieldName: string;
    maxLevel: number;
    maxLevelDescription: string;
}

export interface Details {
    versions: Version[];
    file: File | null;
    type: FileExtensions;
    plfList?: PlfItem[];
    plf?: File | null;
    relationsFile?: File | null;
    customVersionName?: string;
    isBaseline?: boolean;
    isTransformMPP?: boolean;
    isIntegratedProjects?: boolean;
    language?: { value: string | null; label: string } | null;
}

export interface IUploadFiledFetch {
    details: Details;
    contract: ISelectedProject;
    project: ISelectedProject;
}
