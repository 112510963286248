export const getUrlFromEnv = () => {
    let SERVER_PATH_ADMIN;
    let SERVER_PATH_API;
    let SERVER_PATH_PRINT;
    const url = new URL(document.URL);

    try {
        if (process.env.NODE_ENV === 'development') {
            SERVER_PATH_ADMIN = process.env.REACT_APP_BASE_URL_ADMIN;
            SERVER_PATH_API = process.env.REACT_APP_BASE_URL_API;
            SERVER_PATH_PRINT = process.env.REACT_APP_BASE_URL_PRINT;
        } else {
            SERVER_PATH_ADMIN = window['ENV_VARIABLES']['REACT_APP_BASE_URL_ADMIN'].replace('$$_IP$$', url.hostname);
            SERVER_PATH_API = window['ENV_VARIABLES']['REACT_APP_BASE_URL_API'].replace('$$_IP$$', url.hostname);
            SERVER_PATH_PRINT = window['ENV_VARIABLES']['REACT_APP_BASE_URL_PRINT'];
        }
    } catch (e) {
        console.log(`please check env.js exists and is not broken.  ${e}`);
    }

    return { SERVER_PATH_ADMIN, SERVER_PATH_API, SERVER_PATH_PRINT };
};

export const getAPI_URL = () => {
    const { SERVER_PATH_ADMIN, SERVER_PATH_API } = getUrlFromEnv();
    const project = 'project/';

    return {
        LOGIN: `${SERVER_PATH_ADMIN}login`,
        PERFORM_FORGOT_PASSWORD: `${SERVER_PATH_ADMIN}forgotPassword`,
        PERFORM_RESEND_INVITE: `${SERVER_PATH_ADMIN}resendInviteEmail`,
        FETCH_PASSWORD_PATTERN_DATA: `${SERVER_PATH_ADMIN}getPasswordPattern`,
        LOGOUT: `${SERVER_PATH_ADMIN}logout`,
        PERFORM_RESET_PASSWORD: `${SERVER_PATH_ADMIN}resetPassword`,
        CHANGE_PASSWORD: `${SERVER_PATH_ADMIN}changePassword`,
        CREATE_USER: `${SERVER_PATH_ADMIN}createUser`,
        UPDATE_USER: `${SERVER_PATH_ADMIN}updateUser`,
        GET_USER_DETAILS: `${SERVER_PATH_API}getUserDetails`,
        GET_ACCESS_TOKEN: `${SERVER_PATH_API}getUuid`,
        FETCH_SCURVE_DATA: `${SERVER_PATH_API}getSCurveData`,
        UPLOAD_USER_IMAGE_BY_EMAIL: `${SERVER_PATH_API}uploadUserImageByUserName`,
        GET_PROJECT_START_END_DATE: `${SERVER_PATH_ADMIN}getProjectMinMaxDate`,
        FETCH_COST_COMPONENTS_DATA: `${SERVER_PATH_API}getCostComponents`,
        FETCH_COST_EVOLUTION_DATA: `${SERVER_PATH_API}getCostEvolutionData`,
        FETCH_FILTERS_DATA: `${SERVER_PATH_ADMIN}getFiltersData`,
        FETCH_OVERRUN_BENCHMARK_DATA: `${SERVER_PATH_API}getReferenceClass`,
        FETCH_COST_CONTRACT_PERFORMANCE_DATA: `${SERVER_PATH_API}getCostContractPerformanceData`,
        FETCH_COST_DASHBOARD_DATA: `${SERVER_PATH_API}getCostDashboardData`,
        COST_FETCH_DRILL_DOWN_DATA: `${SERVER_PATH_API}getCostComponetsDrillDownData`,
        PUBLISH: `${SERVER_PATH_API}upload/publishLastData`,
        ROLLBACK: `${SERVER_PATH_API}upload/rollbackLastData`,
        CHECK_MFA_ENABLED: `${SERVER_PATH_ADMIN}checkMFAEnabled`,
        GET_MFA_CODE: `${SERVER_PATH_ADMIN}getMFACode`,
        GET_QR_CODE: `${SERVER_PATH_ADMIN}getQRCode`,
        VALIDATE_LOGIN: `${SERVER_PATH_ADMIN}validateLogin`,

        //Programme
        FETCH_PROGRAM_DASHBOARD_HEROES_DATA: `${SERVER_PATH_API}getTopHeroesData`,
        FETCH_PROGRAM_DASHBOARD_KEY_MILESTONES_DATA: `${SERVER_PATH_API}getProjectMilestone`,
        FETCH_PROGRAM_DASHBOARD_CRITICAL_CONTRACTS_DATA: `${SERVER_PATH_API}getMostCriticalContracts`,
        FETCH_PROGRAM_DASHBOARD_PROJECT_GALLERY_DATA: `${SERVER_PATH_API}getProgramImage`,
        FETCH_PROGRAM_DASHBOARD_MAN_POWER_DATA: `${SERVER_PATH_API}getManpower`,
        FETCH_PROGRAM_DASHBOARD_STORED_XER_FILE_VERSIONS: `${SERVER_PATH_API}getStoredXerFileVersions`,
        GET_TAGS_PER_CONTRACT: `${SERVER_PATH_API}getTagsPerContract`,
        UPLOAD_PROGRAM_DASHBOARD_XER_FILE_VERSIONS: `${SERVER_PATH_API}uploadXerFileVersions`,
        UPLOAD_XER_PROJECT_FILE: `${SERVER_PATH_API}uploadProjectFile`,
        FETCH_PROGRAM_DASHBOARD_HIGH_LEVEL: `${SERVER_PATH_API}highLevelProgram`,
        REMOVE_PROGRAM_DASHBOARD_VERSION: `${SERVER_PATH_API}removeVersion`,
        DOWNLOAD_XER_FILE: `${SERVER_PATH_API}downloadXerFile`,
        FETCH_PROGRAM_DASHBOARD_MILESTONE: `${SERVER_PATH_API}getMilestone`,
        FETCH_PROGRAM_DASHBOARD_HEATMAP_START: `${SERVER_PATH_API}getHeatMapActivityStart`,
        FETCH_GET_SHARED_RESOURCES_FOR_OVERLAPPING_ACTIVITIES: `${SERVER_PATH_API}getSharedResourcesForOverlappingActivities`,
        FETCH_PROGRAM_DASHBOARD_HEATMAP_DURATION: `${SERVER_PATH_API}getHeatMapActivityDuration`,
        FETCH_PROGRAM_DASHBOARD_HEATMAP_START_OR_FINISH: `${SERVER_PATH_API}getHeatMapActivityStartOrFinish`,
        FETCH_PROGRAM_DASHBOARD_TOP_DELAY_DRIVERS: `${SERVER_PATH_API}getDelayDriver`,
        FETCH_PROGRAM_DASHBOARD_GET_ACTIVITY_CODES: `${SERVER_PATH_API}getActivityCodesByVersion`,
        FETCH_PROGRAM_DASHBOARD_MILESTONES_WEIGHT: `${SERVER_PATH_API}getMilestoneWeightScurveGraph`,
        FETCH_PROGRAM_DASHBOARD_ACTIVITIES_WEIGHT: `${SERVER_PATH_API}getActivitiesCompleteScurveGraph`,
        FETCH_PROGRAM_DASHBOARD_CRITICALITY_WEIGHT: `${SERVER_PATH_API}getCriticalityWeightScurveGraph`,
        FETCH_PROGRAM_GENERAL_HIERARCHY_LIST: `${SERVER_PATH_API}project/getProgramGeneralHierarchyList`,
        GET_VERSION_DETAILS: `${SERVER_PATH_API}getVersionDetails`,
        FETCH_PROGRAM_DASHBOARD_HEATMAP_RESOURCES: `${SERVER_PATH_API}getHeatMapResources`,
        FETCH_PROGRAM_DASHBOARD_RCF_CLUSTER_DATA: `${SERVER_PATH_API}getClusterData`,
        FETCH_PROGRAM_DASHBOARD_RCF_CLUSTER_DATA_SEARCH_WORD: `${SERVER_PATH_API}getCustomCluster`,
        FETCH_PROGRAM_DASHBOARD_RCF_CLUSTER_DATA_SEARCH_WORDS_LIST: `${SERVER_PATH_API}getCustomClusterList`,
        FETCH_PROGRAM_DASHBOARD_RCF_CLUSTER_DATA_SAVE_SEARCH_WORD: `${SERVER_PATH_API}saveCustomCluster`,
        FETCH_PROGRAM_DASHBOARD_RCF_CLUSTER_DATA_DELETE_SEARCH_WORD: `${SERVER_PATH_API}deleteCustomCluster`,
        FETCH_PROGRAM_DASHBOARD_RCF_CLUSTERS_LIST: `${SERVER_PATH_API}getClustersList`,
        FETCH_PROGRAM_DASHBOARD_RCF_MODIFY_CLUSTERS_LIST: `${SERVER_PATH_API}modifyClustersList`,
        FETCH_PROGRAM_DASHBOARD_RCF_RESET_CLUSTERS_LIST: `${SERVER_PATH_API}resetClustersList`,
        FETCH_PROGRAM_DASHBOARD_ACTIVITIES_GRAPH: `${SERVER_PATH_API}getActivitiesGraph`,
        PROGRAM_DASHBOARD_PLF_ANALYSIS: `${SERVER_PATH_API}plfAnalysis`,
        FETCH_PROGRAM_CROSS_VERSION_ANALYSIS_DATA: `${SERVER_PATH_API}getCrossVersionsTrends`,
        FETCH_PROGRAM_PROGRESS_BASELINE_VS_ACTUAL_DATA: `${SERVER_PATH_API}getBaselineVsActual`,
        FETCH_TOP_DURATION_OVERRUN_RISK_DATA: `${SERVER_PATH_API}getTopDurationOverrunRisk`,
        FETCH_TOP_OVERRUN_ACTIVITIES_DATA: `${SERVER_PATH_API}getTopOverrunActivities`,
        FETCH_PROGRAM_DASHBOARD_GANTT_CHART: `${SERVER_PATH_API}getGantActivitiesGraph`,
        FETCH_PROGRAM_DASHBOARD_ACTIVITIES_SUMMARY: `${SERVER_PATH_API}getActivitiesSummary`,
        GET_FRAGNET_MILESTONES: `${SERVER_PATH_API}getFragnetMilestones`,
        GET_WBS: `${SERVER_PATH_API}getWBSs`,
        EXTRACT_DELAY_DRIVER_CSV: `${SERVER_PATH_API}extractDelayDriverCsv`,
        EXTRACT_DELAY_ACTIVITIES_CSV: `${SERVER_PATH_API}extractDelayedActivitiesCsv`,
        EXTRACT_ROWS_BOARD_CSV: `${SERVER_PATH_API}extractBattleCardToCsv`,
        EXTRACT_ROWS_BOARD_XLS: `${SERVER_PATH_API}extractBattleCardToXls`,

        RENAME_PROJECT: `${SERVER_PATH_API}management/updateProject`,
        RENAME_SUB_PROGRAM: `${SERVER_PATH_API}management/updateContract`,
        RENAME_VERSION: `${SERVER_PATH_API}renameVersion`,
        GET_LANGUAGE_LIST: `${SERVER_PATH_API}getLanguages`,

        FETCH_PROGRAM_SCOPE_TO_GO_GET_ALL_CARDS: `${SERVER_PATH_API}getFilteredCards`,
        GET_LIST_FILTERED_CARDS: `${SERVER_PATH_API}getListFilteredCards`,
        FETCH_PROGRAM_SCOPE_TO_GO_GET_CARD: `${SERVER_PATH_API}getCard`,
        FETCH_PROGRAM_SCOPE_TO_GO_UPDATE_CARD_LANE: `${SERVER_PATH_API}changeCardLane`,
        FETCH_PROGRAM_SCOPE_TO_GO_GET_CARD_INIT: `${SERVER_PATH_API}getCardInit`,
        FETCH_PROGRAM_SCOPE_TO_GO_GET_BOARD_INIT: `${SERVER_PATH_API}getBoardInit`,
        FETCH_PROGRAM_SCOPE_TO_GO_GET_BOARD_WBS_INIT: `${SERVER_PATH_API}getBoardWbsInit`,
        FETCH_PROGRAM_SCOPE_TO_GO_GET_BOARD_TASK_INIT: `${SERVER_PATH_API}getBoardTaskInit`,
        FETCH_PROGRAM_SCOPE_TO_GO_CREATE_EDIT_CARD: `${SERVER_PATH_API}createOrUpdateCard`,
        FETCH_PROGRAM_SCOPE_TO_GO_GET_CARD_RELATED_ACTIVITY: `${SERVER_PATH_API}getRelatedActivity`,
        GET_CURRENT_ACTUAL_DATES: `${SERVER_PATH_API}getCardDates`,
        GET_FINISH_DATE_DUE_TO_EDIT_START_DATE_OR_DURATION: `${SERVER_PATH_API}getFinishDateDueToEditStartDateOrDuration`,
        FETCH_PROGRAM_SCOPE_TO_GO_GET_DELETE_CARD: `${SERVER_PATH_API}removeCard`,
        FETCH_PROGRAM_SCOPE_TO_GO_CREATE_EDIT_COMMENT: `${SERVER_PATH_API}createOrUpdateCardComment`,
        FETCH_PROGRAM_SCOPE_TO_GO_DELETE_COMMENT: `${SERVER_PATH_API}removeCardComment`,
        GET_CARD_COMMENTS: `${SERVER_PATH_API}getCardComment`,
        FETCH_PROGRAM_SCOPE_TO_GO_ACTIVITY_RELATIONS: `${SERVER_PATH_API}getActivityRelations`,
        FETCH_PROGRAM_SCOPE_TO_GO_GET_COUNTERS: `${SERVER_PATH_API}getCardsHeaderView`,
        FETCH_PROGRAM_SCOPE_TO_GO_GET_ACTIVITY_LOG: `${SERVER_PATH_API}getAuditedCard`,
        FETCH_PROGRAM_SCOPE_TO_GO_GET_RELATED_CARDS: `${SERVER_PATH_API}getRelatedCards`,
        FETCH_PROGRAM_SCOPE_TO_GO_SAVE_FILTER: `${SERVER_PATH_API}storeFilter`,
        FETCH_PROGRAM_SCOPE_TO_GO_SAVE_FILTER_DELETE: `${SERVER_PATH_API}deleteFilter`,
        FETCH_PROGRAM_SCOPE_TO_GO_ACTIVITY_DETAILS_BY_TASK_ID: `${SERVER_PATH_API}getActivityMainDetailsByTaskdId`,
        FETCH_PROGRAM_SCOPE_TO_GO_BULK_USER: `${SERVER_PATH_API}bulkAssignToUser`,
        FETCH_PROGRAM_SCOPE_TO_GO_BULK_TAGS: `${SERVER_PATH_API}bulkAssignTagsToCards`,
        FETCH_PROGRAM_SCOPE_TO_GO_BULK_TAGS_REMOVE: `${SERVER_PATH_API}bulkDeleteTags`,
        FETCH_PROGRAM_SCOPE_TO_GO_BULK_DELETE_ACTION: `${SERVER_PATH_API}bulkDeleteUserActionsAndRecomendations`,
        FETCH_PROGRAM_SCOPE_TO_GO_BULK_ROLLBACK_ACTION: `${SERVER_PATH_API}bulkRollbackInterimStatus`,
        UPLOAD_CARD_FILE: `${SERVER_PATH_API}uploadCardFile`,
        UPLOAD_USER_IMAGE: `${SERVER_PATH_API}uploadUserImageByUserName`,
        GET_USER_IMAGE: `${SERVER_PATH_API}getUserImage`,
        FETCH_PROGRAM_GET_BASE_LINE_DATE_OPTION: `${SERVER_PATH_API}getBaseLineDateOption`,
        FETCH_PROGRAM_GET_SUB_PROGRAM_TOGGLE_DATE: `${SERVER_PATH_API}getSubProgramPreferenceToggleDate`,
        FETCH_PROGRAM_GET_SUB_PROGRAM_GET_ACTIVITY_CODES_ALIASES: `${SERVER_PATH_API}getActivityCodesAliases`,
        FETCH_PROGRAM_GET_SUB_PROGRAM_GET_ACTIVITY_CODES_BY_CONTRACT: `${SERVER_PATH_API}getActivityCodesByContract`,
        FETCH_PROGRAM_GET_SUB_PROGRAM_GET_TAG_LIST: `${SERVER_PATH_API}management/getContract`,
        FETCH_PROGRAM_GET_SUB_PROGRAM_GET_TAG_LIST_INIT: `${SERVER_PATH_API}management/getContractTagsInit`,
        FETCH_PROGRAM_GET_SUB_PROGRAM_GET_INFO: `${SERVER_PATH_API}getSubProgramPreferenceInfo`,
        FETCH_PROGRAM_GET_ACROSS_VERSION_HASH_CODE_CONFIG: `${SERVER_PATH_API}getAcrossVersionHashCodeConfig`,
        GET_PROGRESS_PREFERENCES: `${SERVER_PATH_API}getProgressPreferences`,
        SET_PROGRESS_PREFERENCES: `${SERVER_PATH_API}setProgressPreferences`,
        FETCH_PROGRAM_SET_BASE_LINE_DATE_OPTION: `${SERVER_PATH_API}setBaseLineDateOption`,
        FETCH_PROGRAM_SET_SUB_PROGRAM_TOGGLE_DATE: `${SERVER_PATH_API}setSubProgramPreferenceToggleDate`,
        FETCH_PROGRAM_SET_SUB_PROGRAM_CREATE_ALIAS: `${SERVER_PATH_API}createActivityCodeAlias`,
        FETCH_PROGRAM_SET_SUB_PROGRAM_EDIT_ALIAS: `${SERVER_PATH_API}updateActivityCodeAlias`,
        FETCH_PROGRAM_SET_SUB_PROGRAM_DELETE_ALIAS: `${SERVER_PATH_API}deleteActivityCodeAliasById`,
        FETCH_PROGRAM_SET_SUB_PROGRAM_UPDATE_TAG_LIST: `${SERVER_PATH_API}management/updateContract`,
        FETCH_PROGRAM_SET_ACROSS_VERSION_HASH_CODE_CONFIG: `${SERVER_PATH_API}setAcrossVersionHashCodeConfig`,
        GET_SUB_PROGRAM_PREFERENCE_CRITICAL_PATH_COMPARE_VALUE: `${SERVER_PATH_API}getSubProgramPreferenceCriticalPathCompareValue`,
        SET_SUB_PROGRAM_PREFERENCE_CRITICAL_PATH_COMPARE_VALUE: `${SERVER_PATH_API}setSubProgramPreferenceCriticalPathCompareValue`,
        GET_SUB_PROGRAM_PREFERENCE_IMPORTANCE: `${SERVER_PATH_API}getSubProgramPreferenceImportance`,
        SET_SUB_PROGRAM_PREFERENCE_IMPORTANCE: `${SERVER_PATH_API}setSubProgramPreferenceImportance`,
        SEND_INSIGHT_EMAIL_NOTIFICATION: `${SERVER_PATH_API}notification/insightEmailNotification`,
        GENERATE_AI_MITIGATION: `${SERVER_PATH_API}chat/getAiTaskMitigation`,
        GENERATE_AI_SUB_TASK_MITIGATION: `${SERVER_PATH_API}chat/getAiSubTaskMitigation`,
        GENERATE_AI_RESOURCE_MITIGATION: `${SERVER_PATH_API}chat/getAiResourcesMitigation`,
        GENERATE_AI_RISK_SUMMARY: `${SERVER_PATH_API}chat/getAiRiskSummary`,

        //Program Status
        FETCH_PROGRAM__STATUS_PRIORITIES_MATRIX_DATA: `${SERVER_PATH_API}getCardMatrixCountersData`,
        FETCH_PROGRAM__STATUS_PRIORITIES_MATRIX_CARDS: `${SERVER_PATH_API}getCardMatrixList`,
        FETCH_PROGRAM__STATUS_SCORE_CARDS: `${SERVER_PATH_API}getProjectStatusScoreCards`,
        FETCH_PROGRAM__KEY_ACHIEVEMENTS_TABLE_DATA: `${SERVER_PATH_API}getKeyAchievements`,
        FETCH_PROGRAM__KEY_ACHIEVEMENTS_TABLE_FILTERS: `${SERVER_PATH_API}getFilterByComponent`,

        // Logic Checker
        LOGIC_CHECKER_VALIDATION_TYPE_DRILLDOWN: `${SERVER_PATH_API}getValidationTypeDrillDown`,
        LOGIC_CHECKER_VALIDATION_ACTIVITIES_DRILLDOWN: `${SERVER_PATH_API}getValidationActivitiesDrillDown`,
        LOGIC_CHECKER_SET_CONFIG_PARAMS: `${SERVER_PATH_API}setCheckerConfigParams`,
        LOGIC_CHECKER_RESET_CONFIG_PARAMS: `${SERVER_PATH_API}resetConfigOfSingleChecker`,
        LOGIC_CHECKER_COPY_QUALITY_SETTINGS: `${SERVER_PATH_API}copyLogicCheckerConfigurationBetweenContracts`,
        LOGIC_CHECKER_ROW_DRILLDOWN_ACTIVITIES: `${SERVER_PATH_API}getRowDrillDownActivities`,
        LOGIC_CHECKER_SUMMARY: `${SERVER_PATH_API}getLogicCheckerSummary`,
        LOGIC_CHECKER_CATEGORIES: `${SERVER_PATH_API}getLogicCheckerCategoryResults`,
        LOGIC_CHECKER_CONFIGURATION: `${SERVER_PATH_API}getLogicCheckerConfiguration`,
        LOGIC_CHECKER_SET_CHECK_MANDATORY: `${SERVER_PATH_API}setCheckMandatorily`,
        LOGIC_CHECKER_SET_ASSOCIATE_CARD_WITH_LOGIC_CHECKER: `${SERVER_PATH_API}associateCardWithLogicChecker`,

        // Version Compare
        VERSION_COMPARE_GET_TOP_STATUS_CHANGE: `${SERVER_PATH_API}getTopStatusChanges`,
        VERSION_COMPARE_GET_TOP_START_DATE_CHANGES: `${SERVER_PATH_API}getTopStartDateChanges`,
        VERSION_COMPARE_GET_TOP_FINISH_DATE_CHANGES: `${SERVER_PATH_API}getTopFinishDateChanges`,
        VERSION_COMPARE_GET_LARGEST_DURATION_VARIANCE: `${SERVER_PATH_API}getLargestDurationVariance`,
        VERSION_COMPARE_GET_LARGEST_FLOAT_CHANGES: `${SERVER_PATH_API}getLargestFloatChanges`,
        VERSION_COMPARE_GET_TOP_RELATIONSHIP_TYPE_CHANGES: `${SERVER_PATH_API}getTopRelationshipTypeChanges`,
        VERSION_COMPARE_GET_TOP_RELATIONSHIP_COUNT_CHANGES: `${SERVER_PATH_API}getTopRelationshipCountChanges`,
        VERSION_COMPARE_GET_LONGEST_ADDED_ACTIVITIES: `${SERVER_PATH_API}getLongestAddedActivities`,
        VERSION_COMPARE_GET_LONGEST_DELETED_ACTIVITIES: `${SERVER_PATH_API}getLongestDeletedActivities`,
        VERSION_COMPARE_GET_TOP_RECOMMENDATION_CHANGES: `${SERVER_PATH_API}getTopRecommendationChanges`,
        VERSION_COMPARE_GET_TRACKED_ITEMS: `${SERVER_PATH_API}getTrackedItems`,
        VERSION_COMPARE_TRACK_UN_TRACK_ITEMS: `${SERVER_PATH_API}trackUntrackItems`,
        VERSION_COMPARE_GET_SUMMARY_LINE: `${SERVER_PATH_API}getVersionSummaryCards`,
        VERSION_COMPARE_EXPORT_CSV_REPORT: `${SERVER_PATH_API}exportCsvReport`,

        //Project
        FETCH_PROJECT_DATA_STORIES_DELETE: `${SERVER_PATH_API}${project}deleteDataStory`,
        FETCH_PROJECT_DATA_STORIES_POST: `${SERVER_PATH_API}${project}uploadDataStory`,
        FETCH_PROJECT_DATA_STORIES: `${SERVER_PATH_API}${project}getDataStories`,
        FETCH_PROJECT_SINGLE_DATA_STORY: `${SERVER_PATH_API}${project}getDataStory`,
        FETCH_PROJECT_EXTERNAL_DETAILS_DATA: `${SERVER_PATH_API}${project}getExternalReportDetails`,
        GET_EXTERNAL_REPORT_LIST: `${SERVER_PATH_API}${project}getExternalReportList`,
        FETCH_PROJECT_DASHBOARD_HEROES_DATA: `${SERVER_PATH_API}${project}getProjectTopKpi`,
        FETCH_PROJECT_DASHBOARD_KEY_DATES_DATA: `${SERVER_PATH_API}${project}getKeyDates`,
        FETCH_PROJECT_DASHBOARD_BIOGRAPHY_DATA: `${SERVER_PATH_API}${project}getBiographyEvents`,
        FETCH_PROJECT_DASHBOARD_GOVERNANCE_DATA: `${SERVER_PATH_API}${project}getProjectGovernance`,
        FETCH_PROJECT_DASHBOARD_GOVERNANCE_DRILL_DOWN_DATA: `${SERVER_PATH_API}${project}getProjectGovernanceDrillDown`,
        FETCH_PROJECT_DASHBOARD_GOVERNANCE_DRILL_DOWN_APPROVE_CHECKPOINT: `${SERVER_PATH_API}${project}setCheckpointStatus`,
        FETCH_PROJECT_DASHBOARD_GOVERNANCE_DRILL_DOWN_SUBMIT_VERIFICATION: `${SERVER_PATH_API}${project}verifyCheckPoint`,

        CREATE_GOVERNANCE_CHECKPOINT: `${SERVER_PATH_API}${project}createGovernanceCheckpoint`,
        UPDATE_GOVERNANCE_CHECKPOINT: `${SERVER_PATH_API}${project}updateGovernanceCheckpoint`,
        DELETE_GOVERNANCE_CHECKPOINT: `${SERVER_PATH_API}${project}deleteGovernanceCheckpoint`,

        CREATE_GOVERNANCE_CHECKPOINT_ITEM: `${SERVER_PATH_API}${project}createGovernanceCheckpointItem`,
        UPDATE_GOVERNANCE_CHECKPOINT_ITEM: `${SERVER_PATH_API}${project}updateGovernanceCheckpointItem`,
        DELETE_GOVERNANCE_CHECKPOINT_ITEM: `${SERVER_PATH_API}${project}deleteGovernanceCheckpointItem`,

        // Project - biography cards
        POST_BIOGRAPHY_DATA: `${SERVER_PATH_API}project/createBiographyData`,
        UPDATE_BIOGRAPHY_DATA: `${SERVER_PATH_API}project/updateBiographyData`,
        DELETE_BIOGRAPHY_DATA: `${SERVER_PATH_API}project/deleteBiography`,

        FETCH_PROJECT_DASHBOARD_PEOPLE_DATA: `${SERVER_PATH_API}${project}getProjectPeople`,
        FETCH_PROJECT_DASHBOARD_PEOPLE_DRILL_DOWN_DATA: `${SERVER_PATH_API}${project}getProjectPeopleDrillDown`,
        FETCH_PROJECT_DASHBOARD_PEOPLE_DRILL_UPDATE_DATA: `${SERVER_PATH_API}${project}updateProjectPeople`,

        FETCH_PROJECT_DASHBOARD_PEOPLE_GALLERY_ADD_ITEM: `${SERVER_PATH_API}uploadProgramImage`,
        FETCH_PROJECT_DASHBOARD_PEOPLE_GALLERY_DELETE_ITEM: `${SERVER_PATH_API}deleteProgramImage`,

        FETCH_PROJECT_DASHBOARD_LESSONS_LEARNT_DATA: `${SERVER_PATH_API}${project}getLessonLearnedData`,
        FETCH_PROJECT_DASHBOARD_LESSONS_LEARNT_DRILL_DOWN_DATA: `${SERVER_PATH_API}${project}getProjectLessonsLearntDrillDown`,
        CREATE_LESSONS_LEARNT_DATA: `${SERVER_PATH_API}${project}createLessonsLearntData`,
        DELETE_LESSONS_LEARNT_DATA: `${SERVER_PATH_API}${project}deleteLessonLearned`,
        UPDATE_LESSONS_LEARNT_DATA: `${SERVER_PATH_API}${project}updateLessonsLearntData`,

        FETCH_FILTER_TABLE_ON_SCROLL: `${SERVER_PATH_API}getCostDrillDownTableData`,

        FETCH_SETTING_USER_ROLES_LIST: `${SERVER_PATH_ADMIN}getRolesList`,
        FETCH_SETTING_GET_USER_BY_NAME: `${SERVER_PATH_ADMIN}getUserByName`,
        FETCH_SETTING_USERS_LIST: `${SERVER_PATH_ADMIN}getUsersList`,
        DELETE_USER_BY_USERNAME: `${SERVER_PATH_ADMIN}delete`,

        DELETE_ROLE_FROM_LIST: `${SERVER_PATH_ADMIN}roleDelete`,
        CREATE_NEW_ROLE: `${SERVER_PATH_ADMIN}roleCreate`,
        EDIT_ROLE_BY_ID: `${SERVER_PATH_ADMIN}roleUpdateByID`,
        FETCH_GET_PRIVILEGES_LIST: `${SERVER_PATH_ADMIN}privilegeList`,

        EXPORT_CSV: `${SERVER_PATH_API}exportCsv`,

        FETCH_BUDGET_BREAKDOWN: `${SERVER_PATH_API}getBudgetBreakdownData`,

        FETCH_DATA_UPLOAD_ERRORS: `${SERVER_PATH_API}upload/getErrorList`,
        EXPORT_CSV_DATA_UPLOAD_ERRORS: `${SERVER_PATH_API}upload/exportErrorsToCsv`,
        //Annotations
        FETCH_ANNOTATION_ADD_OR_UPDATE: `${SERVER_PATH_API}setOrUpdateAnnotation`,
        FETCH_ANNOTATION_DELETE: `${SERVER_PATH_API}deleteAnnotation`,

        // Portfolio
        PHASE_PERFORMANCE_CREATE_TEMPLATE: `${SERVER_PATH_API}phaseperformance/createGateTemplate`,
        PHASE_PERFORMANCE_UPDATE_TEMPLATE: `${SERVER_PATH_API}phaseperformance/updateGateTemplate`,
        PHASE_PERFORMANCE_GET_TEMPLATE: `${SERVER_PATH_API}phaseperformance/getTemplate`,
        PHASE_PERFORMANCE_DELETE_TEMPLATE: `${SERVER_PATH_API}phaseperformance/deleteGateTemplate`,
        PHASE_PERFORMANCE_DELETE_MAPPING: `${SERVER_PATH_API}phaseperformance/deleteMapping`,
        PHASE_PERFORMANCE_GET_All_TEMPLATES: `${SERVER_PATH_API}phaseperformance/getTemplateNames`,
        PHASE_PERFORMANCE_CREATE_MAPPING: `${SERVER_PATH_API}phaseperformance/mapToContract`,
        PHASE_PERFORMANCE_GET_MILESTONES: `${SERVER_PATH_API}phaseperformance/getMilestones`,
        PHASE_PERFORMANCE_GET_MAPPING: `${SERVER_PATH_API}phaseperformance/getMapping`,

        // common
        FETCH_CUSTOMER_USERS: `${SERVER_PATH_API}getUsers`,
        FETCH_UNREAD_NOTIFICATIONS_COUNTER: `${SERVER_PATH_API}notification/getUnreadCounter`,
        FETCH_NOTIFICATIONS: `${SERVER_PATH_API}notification/getList`,
        SET_NOTIFICATIONS_READ_STATUS: `${SERVER_PATH_API}notification/setListReadStatus`,
        GET_NOTIFICATIONS_CONNECTION_STATUS: `${SERVER_PATH_API}notification/getNotificationStatus`,
        CREATE_CONTRACT: `${SERVER_PATH_API}management/createContract`,
        GET_API_VERSION: `${SERVER_PATH_ADMIN}getVersion`,
        CREATE_PROJECT: `${SERVER_PATH_API}management/createProject`,
        GET_EMAIL_NOTIFICATION_STATUS: `${SERVER_PATH_API}notification/getEmailNotificationStatus`,
        SET_EMAIL_NOTIFICATION_STATUS: `${SERVER_PATH_API}notification/setEmailNotificationStatus`,
        DELETE_SUB_PROGRAM: `${SERVER_PATH_API}management/deleteContract`,
        DELETE_PROJECT: `${SERVER_PATH_API}management/deleteProjectWithConstraints`,
        REFRESH_PROGRAM_ANALYSIS: `${SERVER_PATH_API}refreshProgramAnalysisData`,
        GET_USER_GROUPS: `${SERVER_PATH_ADMIN}getAllUserGroups`,
        UPDATE_USER_GROUP: `${SERVER_PATH_ADMIN}updateUserGroup`,
        CREATE_USER_GROUP: `${SERVER_PATH_ADMIN}createUserGroup`,
        DELETE_USER_GROUP: `${SERVER_PATH_ADMIN}deleteUserGroup`,
        RENAME_USER_GROUP: `${SERVER_PATH_ADMIN}renameUserGroup`,
        FETCH_PORTFOLIO_TOP_HEROES: `${SERVER_PATH_API}getPortfolioTopHeroes`,
        FETCH_PROJECT_SCORE_CARDS_SUMMARY: `${SERVER_PATH_API}getProjectScoreCardsSummary`,
        GET_HEATMAP_SUMMARY: `${SERVER_PATH_API}getHeatMapSummary`,
        SET_HOME_PAGE: `${SERVER_PATH_ADMIN}setHomePage`,
        GET_CLUSTER_TYPE: `${SERVER_PATH_API}getClusterType`,
        CHANGE_CLUSTER_TYPE: `${SERVER_PATH_API}changeClusterType`,

        GET_DASHBOARDS: `${SERVER_PATH_API}getDashboardList`,
        SET_DASHBOARD_BY_ID: `${SERVER_PATH_API}setDashboard`,
        CREATE_DASHBOARD: `${SERVER_PATH_API}createDashboard`,
        DELETE_DASHBOARD: `${SERVER_PATH_API}deleteDashboardById`,

        GET_TABLE_COLUMNS: `${SERVER_PATH_API}getTableColumns`,
        SET_TABLE_COLUMNS: `${SERVER_PATH_API}setTableColumns`,

        SET_WIDGET_COLUMNS: `${SERVER_PATH_API}setWidgetColumns`,
        GET_WIDGET_COLUMNS: `${SERVER_PATH_API}getWidgetColumnsByID`,
        GET_TEMPLATES_NAMES: `${SERVER_PATH_API}phaseperformance/getTemplateNames`,
        GET_MAPPED_CONTRACTS: `${SERVER_PATH_API}phaseperformance/getMappedContracts`,
        GET_STORY_IMAGE: `${SERVER_PATH_API}project/getStoryImage`,
        GET_SERVER_TIME: `${SERVER_PATH_ADMIN}getServerTime`,
        GET_USER_EXT_DOMAIN: `${SERVER_PATH_API}getUserExtDomain`,
        GET_STORED_TAGS: `${SERVER_PATH_API}getStoredTags`,
        GET_TAGS_ASSIGNED_TO_CURRENT_CUSTOMER_SUB_PROGRAMS: `${SERVER_PATH_API}getTagsAssignedToCurrentCustomerSubPrograms`,
        UPDATE_TAG: `${SERVER_PATH_API}updateTag`,
        DELETE_TAG: `${SERVER_PATH_API}deleteTagByCustomerIdAndName`,
        CREATE_TAG: `${SERVER_PATH_API}createTag`,
        GET_TEMPLATE_RELATED_PROJECTS: `${SERVER_PATH_API}phaseperformance/getTemplateRelatedProjects`,

        // Completion Plan Page
        GET_ACTIVITY_COMPLETION_PLAN: `${SERVER_PATH_API}getActivityCompletionPlan`,
        GET_TOP_CRITICAL_PATH_ADDED: `${SERVER_PATH_API}getTopCriticalPathAdded`,
        GET_TOP_CRITICAL_PATH_REMOVED: `${SERVER_PATH_API}getTopCriticalPathRemoved`,
        EXPORT_COMPLETION_PLAN_CSV: `${SERVER_PATH_API}exportCompletionPlanToCsv`,

        CHANGE_ASSIGNEE: `${SERVER_PATH_API}changeAssignee`,
        GET_ASSIGNEE_LIST: `${SERVER_PATH_API}getAsigneList`,
        GET_SELECT_OPTIONS: `${SERVER_PATH_API}management/getCommonObjectsList`,
        GET_INTEGRATED_SCHEDULE_VERSION: `${SERVER_PATH_API}getIntegratedScheduleVersion`,
        GET_INTEGRATED_SCHEDULE_DETAILS: `${SERVER_PATH_API}getIntegratedScheduleDetails`,
        GET_KEY_ACTIVITIES: `${SERVER_PATH_API}getKeyActivities`,
        SET_KEY_ACTIVITIES_TAGS: `${SERVER_PATH_API}setKeyActivitiesTags`,
        GET_KEY_ACTIVITIES_TAGS: `${SERVER_PATH_API}getKeyActivitiesTags`,
        GET_EXCEL_EXPORT_CONFIG: `${SERVER_PATH_API}getSubProgramPreferenceP6ExcelExport`,
        SET_EXCEL_EXPORT_CONFIG: `${SERVER_PATH_API}setSubProgramPreferenceP6ExcelExport`,
    };
};
