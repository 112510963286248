import React, { useMemo } from 'react';
import classes from './BaselineVsActual.module.scss';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import moment from 'moment-timezone';
import { roundNumber } from 'utilitys/helpers/general';
import constants from 'components/common/Constants/constants';
import { CustomizedProgressBar, CustomizedTooltip } from 'components/common';
import { RadioComponent } from 'components/common/RadioComponent/radioComponent';
import { IBaselineVsActualResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/progress.type';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { useThemeStatus } from 'components/Header/store/themeStatus';
import { Link } from 'react-router-dom';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';

export enum Progress {
    CriticalityWeighted = 'Criticality Weighted',
    MilestonesWeighted = 'Completion Weighted',
}

const BaselineVsActual = ({
    data,
    loading,
    widgetId,
    progressWeighted,
    setWeightedQuery,
    milestoneFragnetTaskVersionHashCode,
}: {
    data: IBaselineVsActualResponse;
    loading: boolean;
    widgetId?: string;
    progressWeighted?: Progress;
    setWeightedQuery: (data: Progress) => void;
    milestoneFragnetTaskVersionHashCode?: string;
}) => {
    const {
        baseLine,
        actual,
        criticalityPlannedPercent,
        milestonesPlannedPercent,
        criticalityActualPercent,
        milestonesActualPercent,
        latestSubmittedAnticipatedDelay,
    } = data;

    const selectProgress = (event) => {
        setWeightedQuery(event.target.value);
    };

    const baseLineValue = roundNumber(
        progressWeighted === Progress.CriticalityWeighted ? criticalityPlannedPercent : milestonesPlannedPercent,
    );
    const actualValue = roundNumber(
        progressWeighted === Progress.CriticalityWeighted ? criticalityActualPercent : milestonesActualPercent,
    );

    const baseLineValueText = isNaN(baseLineValue) ? 'N/A' : `${baseLineValue}%`;
    const actualValueText = isNaN(actualValue) ? 'N/A' : `${actualValue}%`;
    const isDataNull = isNaN(baseLineValue) || isNaN(actualValue);
    const tempScheduleAdherence = baseLineValue === 0 ? 0 : roundNumber(actualValue / baseLineValue);
    const scheduleAdherence = isDataNull ? 'N/A' : tempScheduleAdherence;

    const getScheduleAdherenceColorClass = (value) => {
        if (value < 0.9) {
            return 'red';
        } else if (value >= 0.9 && value < 1) {
            return 'amber';
        } else if (value >= 1) {
            return 'green';
        }
        return 'red';
    };

    const getScheduleAdherenceColor = getScheduleAdherenceColorClass(scheduleAdherence);

    const controlProps = (item) => ({
        checked: progressWeighted === item,
        onChange: selectProgress,
        value: item,
        name: 'progress-option',
    });

    const { themeStatus } = useThemeStatus();
    const PlannedColor = themeStatus
        ? {
              backgroundImage: 'linear-gradient(90deg,#3340a2,#2d77b9 31%,#28abcf)',
          }
        : { backgroundColor: '#5A6DEE' };
    const ActualColor = themeStatus
        ? {
              backgroundImage: 'linear-gradient(90deg, rgb(2, 94, 91), rgb(1, 186, 184) 31%, rgb(0, 254, 254))',
          }
        : { backgroundColor: '#32A4BC' };

    const completionPlanLink = useMemo(() => {
        if (milestoneFragnetTaskVersionHashCode) {
            return `/dashboard/program/${excellenceAppsConfig.completionPlan.link}/?milestoneFragnet=${milestoneFragnetTaskVersionHashCode}`;
        }

        return `/dashboard/program/${excellenceAppsConfig.completionPlan.link}/`;
    }, [milestoneFragnetTaskVersionHashCode]);

    return (
        <div className={classes.baselineVsActualContainer}>
            {loading && <OverlayWithSpinner />}
            <div className={classes.topSection}>
                <div data-testid={'left-side'} className={classes.leftSide}>
                    <div className={classes.progressSelection}>
                        <span>Progress</span>
                        {widgetId ? (
                            <div className={classes.radioContainer}>{progressWeighted}</div>
                        ) : (
                            <div className={classes.radioContainer}>
                                <label>
                                    <RadioComponent {...controlProps(Progress.CriticalityWeighted)} />{' '}
                                    {Progress.CriticalityWeighted}
                                </label>
                                <label>
                                    <RadioComponent {...controlProps(Progress.MilestonesWeighted)} />{' '}
                                    {Progress.MilestonesWeighted}
                                </label>
                            </div>
                        )}
                    </div>
                    {baseLine && (
                        <div data-testid={'base-line-bar'} className={classes.progressBarWrapper}>
                            <span className={classes.progressBarTitle}>Planned</span>
                            <CustomizedProgressBar
                                currentValue={baseLineValue}
                                progressBarStyles={PlannedColor}
                                customTextClass={classes.baseLineText}
                                text={baseLineValueText}
                            />
                        </div>
                    )}
                    {actual && (
                        <div data-testid={'actual-line-bar'} className={classes.progressBarWrapper}>
                            <span className={classes.progressBarTitle}>Actual</span>
                            <CustomizedProgressBar
                                currentValue={actualValue}
                                customTextClass={classes.actualText}
                                progressBarStyles={ActualColor}
                                text={actualValueText}
                            />
                        </div>
                    )}
                </div>
                <div data-testid={'right-side'} className={classes.rightSide}>
                    <div data-testid={'box'} className={`${classes.box} ${classes.current}`}>
                        <span data-testid={'title'} className={classes.title}>
                            Latest Submitted
                            <br /> Anticipated Delay
                        </span>
                        <span data-testid={'value'} className={`${classes.value} ${classes.capitalize}`}>
                            {latestSubmittedAnticipatedDelay}
                        </span>
                    </div>
                    <div data-testid={'box'} className={`${classes.box} ${classes.schedule}`}>
                        <span data-testid={'title'} className={classes.title}>
                            Schedule
                            <br /> Performance
                        </span>
                        <span
                            data-testid={'value'}
                            className={`${classes.value} ${classes[getScheduleAdherenceColor]}`}
                        >
                            {scheduleAdherence}
                        </span>
                        <Link to={completionPlanLink} className={classes.link}>
                            Completion Plan
                        </Link>
                    </div>
                </div>
            </div>
            <div data-testid={'table-selection'} className={classes.tableSection}>
                <table>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Version Name</th>
                            <th>version date</th>
                            <th>start date</th>
                            <th>finish date</th>
                            <th>duration</th>
                            <th>total milestones (remaining)</th>
                            <th>total Tasks (remaining)</th>
                            <th>Max WBS level</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={classes.spacer} />
                        <tr className={classes.spacer} />
                        <tr data-testid={'table-baseline'} className={classes.baseline}>
                            <td>Baseline (planned)</td>
                            <td>
                                <CustomizedTooltip
                                    tooltipPosition={TooltipPosition.Top}
                                    tooltipContent={baseLine.versionName}
                                    triggerElement={<div className={classes.ellipsis}>{baseLine.versionName}</div>}
                                />
                            </td>
                            <td className={classes.noWrap}>
                                {moment(baseLine.versionDate).isValid()
                                    ? moment(baseLine.versionDate).format(constants.formats.date.default)
                                    : 'N/A'}
                            </td>
                            <td className={classes.noWrap}>
                                {moment(baseLine.startDate).isValid()
                                    ? moment(baseLine.startDate).format(constants.formats.date.default)
                                    : 'N/A'}
                            </td>
                            <td className={classes.noWrap}>
                                {moment(baseLine.finishDate).isValid()
                                    ? moment(baseLine.finishDate).format(constants.formats.date.default)
                                    : 'N/A'}
                            </td>
                            <td className={classes.noWrap}>{baseLine.duration} Months</td>
                            <td>
                                {baseLine.totalMilestones.toLocaleString()} (
                                {baseLine.totalMilestonesNotCompleted.toLocaleString()})
                            </td>
                            <td>
                                {baseLine.totalTasks.toLocaleString()} (
                                {baseLine.totalTasksNotCopmleted.toLocaleString()})
                            </td>
                            <td>{baseLine.maxWBSLevel}</td>
                        </tr>
                        <tr className={classes.spacer} />

                        <tr data-testid={'table-latest'} className={classes.latest}>
                            <td>Latest (actual)</td>
                            <td className={classes.noWrap}>
                                <CustomizedTooltip
                                    tooltipPosition={TooltipPosition.Top}
                                    tooltipContent={actual.versionName}
                                    triggerElement={<div className={classes.ellipsis}>{actual.versionName}</div>}
                                />
                            </td>
                            <td>
                                {moment(actual.versionDate).isValid()
                                    ? moment(actual.versionDate).format(constants.formats.date.default)
                                    : 'N/A'}
                            </td>
                            <td>
                                {moment(actual.startDate).isValid()
                                    ? moment(actual.startDate).format(constants.formats.date.default)
                                    : 'N/A'}
                            </td>
                            <td>
                                {moment(actual.finishDate).isValid()
                                    ? moment(actual.finishDate).format(constants.formats.date.default)
                                    : 'N/A'}
                            </td>
                            <td className={classes.noWrap}>{actual.duration} Months</td>
                            <td>
                                {actual.totalMilestones.toLocaleString()} (
                                {actual.totalMilestonesNotCompleted.toLocaleString()})
                            </td>
                            <td>
                                {actual.totalTasks.toLocaleString()} ({actual.totalTasksNotCopmleted.toLocaleString()})
                            </td>
                            <td>{actual.maxWBSLevel}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BaselineVsActual;
