import React, { useEffect, useState } from 'react';
import { RadioComponent } from 'components/common/RadioComponent/radioComponent';
import classes from './criticalPath.module.scss';
import {
    useMutationSetSubProgramPreferenceCriticalPathCompareValue,
    useQueryGetSubProgramPreferenceCriticalPathCompareValue,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/queries/useQuerySubProgramPreferences';
import { useProjectStore } from 'components/common/TreeProgramFilter/store/treeHeaderStore';
import { ConfirmSaveModal, CustomizedButton } from 'components/common';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import SliderComponent from 'components/common/Slider/Slider';

export enum CriticalPathSettingOptions {
    TOTAL_FLOAT = 'TOTAL_FLOAT',
    LONGEST_PATH = 'LONGEST_PATH',
}

export const CriticalPath = () => {
    const {
        selectedProject: { contract, project },
    } = useProjectStore();
    const [actionButtonDisabled, setActionButtonDisabled] = useState<boolean>(true);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [option, setOption] = useState<CriticalPathSettingOptions>(CriticalPathSettingOptions.TOTAL_FLOAT);
    const [days, setDays] = useState<number>(0);
    const { handleSnackBar } = useCustomSnackBar();
    const { data, refetch } = useQueryGetSubProgramPreferenceCriticalPathCompareValue({ subProgramId: contract?.id });
    const { mutate, isLoading } = useMutationSetSubProgramPreferenceCriticalPathCompareValue();

    const resetDefault = () => {
        if (data) {
            setDays(data.defaultCopmareDays);
            setOption(data.defaultCriticalPathSetting);
        }
    };

    useEffect(() => {
        resetDefault();
        setActionButtonDisabled(true);
    }, [data]);

    const handleHideModal = () => {
        setShowConfirmModal(false);
        setActionButtonDisabled(true);
        resetDefault();
    };

    const controlProps = (item: CriticalPathSettingOptions) => ({
        checked: option === item,
        onChange: () => {
            setOption(item);
            setActionButtonDisabled(false);
        },
        value: item,
        name: 'criticalPathOption',
    });

    const handleDaysChange = (e) => {
        setDays(e.target.value);
        if (data?.defaultCopmareDays === e.target.value) {
            setActionButtonDisabled(true);
        } else {
            setActionButtonDisabled(false);
        }
    };

    const handleSave = () => {
        if (contract && project) {
            setShowConfirmModal(false);
            mutate(
                {
                    subProgramId: contract.id,
                    projectId: project.id,
                    option,
                    value: option === CriticalPathSettingOptions.TOTAL_FLOAT ? days : 0,
                    isNeedRefresh: true,
                },
                {
                    onSuccess: () => handleSnackBar('Critical Path Definition has changed', 'success'),
                    onError: (error) => handleSnackBar(error?.response?.data || 'Something went wrong ', 'error'),
                    onSettled: () => refetch(),
                },
            );
        }
    };

    return (
        <div className={classes.criticalPathContainer}>
            <span className={classes.title}>Critical Path Definition:</span>
            <div className={classes.container}>
                <div className={classes.row}>
                    <label>
                        <RadioComponent {...controlProps(CriticalPathSettingOptions.TOTAL_FLOAT)} /> Total float less
                        than or equal to
                    </label>
                    <div className={classes.sliderWrapper}>
                        <SliderComponent
                            value={days}
                            step={1}
                            min={0}
                            max={30}
                            valueLabelDisplay={'on'}
                            onChange={handleDaysChange}
                            disabled={option !== CriticalPathSettingOptions.TOTAL_FLOAT}
                            valueLabelFormat={(value) => <div className={classes.labelSize}>{value}</div>}
                        />
                    </div>
                    days
                </div>
                <div className={classes.row}>
                    <label>
                        <RadioComponent {...controlProps(CriticalPathSettingOptions.LONGEST_PATH)} /> Longest path
                        (supported only for XER)
                    </label>
                </div>

                <div className={classes.actionButtons}>
                    <CustomizedButton color={'secondary'} onClick={handleHideModal}>
                        Cancel
                    </CustomizedButton>
                    <CustomizedButton
                        color={'primary'}
                        onClick={() => setShowConfirmModal(true)}
                        disabled={actionButtonDisabled}
                    >
                        Save
                    </CustomizedButton>
                </div>
            </div>
            <ConfirmSaveModal
                isShowModal={showConfirmModal}
                onHideModal={handleHideModal}
                onConfirm={handleSave}
                isLoading={isLoading}
            >
                You have made changes which require sub-program refresh, which may take a few minutes. Are you sure you
                like to apply this change?
            </ConfirmSaveModal>
        </div>
    );
};
