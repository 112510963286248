import React, { useEffect, useState } from 'react';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/comments/comments.module.scss';
import RichTextArea from 'components/common/richTextArea/richTextArea';
import moment from 'moment-timezone';
import Icon from 'components/common/Icons/icon';
import { CustomizedButton, CustomizedConfirmDeleteModal, CustomizedRoundIcon } from 'components/common';
import { isStringHasRealContent } from 'utilitys/helpers/general';
import useUserHook from 'hooks/useUserHook';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { ProfileImage } from 'components/common/ProfileImage/profileImage';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useCommentsEditorStatus } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/store/commentsEditorStatusStore';
import {
    useMutationCreateEditComment,
    useMutationDeleteComment,
    useQueryGetCardComments,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/commentsMutation';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import constants from 'components/common/Constants/constants';
import { useQueryClient } from 'react-query';
import { Separator } from 'components/common/WidgetWithTitle/Separator/separator';
import { useSubscription } from 'react-stomp-hooks';
import { CommentsAiMenu } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/comments/commentsAiMenu';
import { IComment } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { IGetCardResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useQueryMainCard';
import { useCommentsStore } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/comments/store/store';
import { generatePath, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

const sortCommentsData = (arr) => {
    return arr.sort(function (a, b) {
        return -(a.commentId - b.commentId);
    });
};

interface ICommentsProps {
    id: number;
    isVirtual: boolean;
    assigneeOptions: { text: string; value: string; url: string }[];
    card?: IGetCardResponse;
    queryKeys?: string[];
}

const Comments = ({ id, isVirtual, assigneeOptions, card, queryKeys = [] }: ICommentsProps) => {
    const commentData = useCommentsStore((store) => store.commentData);
    const commentId = useCommentsStore((store) => store.commentId);
    const { setCommentData, setCommentId } = useCommentsStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { commentsEditorStatus, resetCommentsEditorStatus, setCommentsEditorStatus } = useCommentsEditorStatus();
    const queryclient = useQueryClient();

    const { firstName, lastName, username, ability, email, image } = useUserHook();
    const [deleteCommentModalShow, setDeleteCommentModalShow] = useState(false);
    const [removeCommentId, setRemoveCommentId] = useState<number | null>(null);

    const { handleSnackBar } = useCustomSnackBar();

    const [commentError, setCommentError] = useState(false);
    const { sendEventWithDimensions } = useEventWithDimensions();

    const history = useHistory();
    const { path } = useRouteMatch();
    const params = useParams();
    const { search } = useLocation();

    const { data: cardCommentData } = useQueryGetCardComments({ id, isVirtual });
    const { mutate: createEditCommentMutate } = useMutationCreateEditComment();
    const { mutate: deleteCommentMutate } = useMutationDeleteComment();
    /**
     * if trying to save card when comment editor is open, mark red border as error accor
     */
    useEffect(() => {
        if (commentsEditorStatus.isError) {
            setCommentError(true);
        }
    }, [commentsEditorStatus.isError]);

    /**
     * update id and isVirtual
     */
    useEffect(() => {
        if (id && typeof isVirtual !== 'undefined') {
            setCommentData({ ...commentData, cardId: id, isVirtual: isVirtual });
        }
    }, [id, isVirtual]);

    /**
     * displays the comments editor
     */
    const setAddCommentMode = () => {
        setCommentData({ ...commentData, cardId: id, isVirtual: isVirtual, id: null, comment: '' });
        setCommentId(false);
        setCommentError(false);

        // set to zustand store if comments editor is open or close
        // for the save card button, if editor is open don't allow save card
        setCommentsEditorStatus({
            isOpen: true,
            isError: false,
        });
    };

    /**
     * hides the comment editor
     */
    const cancelAddCommentMode = () => {
        setCommentData({ ...commentData, comment: '', mentionedList: [] });
        resetCommentsEditorStatus();
    };

    /**
     * updates the comment value
     * @param value
     * @param mentionedList
     */
    const handleEditorDescription = (value, mentionedList) => {
        setCommentData({ ...commentData, comment: value, mentionedList });
    };

    /**
     * dispatch an action to create the comment
     * 1. checks if comment value is empty after striping HTML tags that was added from the editor
     * 2. if its empty - returns false
     * 3. if card is virtual (card that was created by the BE based on tasks in the version) it runs a callback to create the card and then add the comment data since virtual cards are handling the comment data separately
     * 4. if card is not virtual - dispatch create comment (create and update are in the same API call)
     */
    const createComment = () => {
        const tempSingleCommentData = { ...commentData };

        if (isStringHasRealContent(tempSingleCommentData.comment)) {
            setCommentError(true);
            return;
        }

        setIsLoading(true);
        createEditCommentMutate(tempSingleCommentData, {
            onSuccess: (res) => {
                handleSnackBar('Comment Successfully Saved', 'success');

                return Promise.all([
                    ...queryKeys.map((key) => queryclient.invalidateQueries(key)),
                    queryclient.invalidateQueries('getCardComment'),
                ]).then(() => {
                    setIsLoading(false);
                    resetCommentsEditorStatus();
                    setCommentData({ ...commentData, cardId: res.cardId, isVirtual: false, comment: '' });

                    const link = `${generatePath(path, { ...params, cardId: res.cardId })}${search}`;
                    history.push(link);
                });
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                setIsLoading(false);
            },
        });

        sendEventWithDimensions({
            category: 'Battlecards',
            action: 'Add Comment',
            label: tempSingleCommentData.cardId,
        });
    };

    /**
     * dispatch an action to update a comment
     * 1. checks if comment value is empty after striping HTML tags that was added from the editor
     * 2. if its empty - returns false
     * 3. set the id to the tempComment object
     * 4. dispatch update comment (create and update are in the same API call)
     */
    const updateComment = (commentId) => {
        const tempSingleCommentData = { ...commentData, cardId: id };

        if (isStringHasRealContent(tempSingleCommentData.comment)) {
            setCommentError(true);
            return;
        }

        setIsLoading(true);
        tempSingleCommentData.id = commentId;
        createEditCommentMutate(tempSingleCommentData, {
            onSuccess: (res) => {
                handleSnackBar('Comment Successfully Updated', 'success');

                return Promise.all([
                    ...queryKeys.map((key) => queryclient.invalidateQueries(key)),
                    queryclient.invalidateQueries('getCardComment'),
                ]).then(() => {
                    resetCommentsEditorStatus();
                    setCommentId(false);
                    setIsLoading(false);
                    setCommentData({ ...commentData, cardId: res.cardId, isVirtual: false, comment: '' });
                });
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                setIsLoading(false);
            },
        });

        sendEventWithDimensions({
            category: 'Battlecards',
            action: 'Update Comment',
            label: tempSingleCommentData.cardId,
        });
    };

    const confirmDeleteComment = (id: number) => {
        setRemoveCommentId(id);
        setDeleteCommentModalShow(true);
    };

    const handleCommentDelete = (id: number) => {
        setDeleteCommentModalShow(false);
        deleteCommentMutate(id, {
            onSuccess: () => {
                handleSnackBar('Comment Successfully Deleted', 'success');

                return Promise.all([
                    ...queryKeys.map((key) => queryclient.invalidateQueries(key)),
                    queryclient.invalidateQueries('getCardComment'),
                ]);
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
            },
        });
    };

    const sortedComments: IComment[] = cardCommentData ? sortCommentsData([...cardCommentData]) : [];

    // AI Mitigation Section ====================================================================================
    const isAIMitigationPrivilege = ability.can('view', 'AIMitigation');
    const [isLoadingMitigation, setIsLoadingMitigation] = useState(false);

    useSubscription(email ? [`/topic/${email}.notifications.push`] : [], (response) => {
        const { type } = JSON.parse(response.body);
        if (type === 'AiMitigation') {
            handleSnackBar('Generative-AI response is ready', 'success');

            Promise.all([
                ...queryKeys.map((key) => queryclient.invalidateQueries(key)),
                queryclient.invalidateQueries('getCardComment'),
            ]).then(() => {
                setIsLoadingMitigation(false);
                setCommentId(false);
            });
        }
    });

    return (
        <div className={classes.commentsContainer}>
            {isLoading && <OverlayWithSpinner />}
            <div className={classes.titleWrapper}>
                <div className={classes.title}>Comments:</div>
                {commentsEditorStatus.isOpen && (
                    <div className={classes.actionButtons}>
                        {isAIMitigationPrivilege && card && (
                            <>
                                <CommentsAiMenu
                                    isLoadingMitigation={isLoadingMitigation}
                                    setIsLoadingMitigation={setIsLoadingMitigation}
                                    card={card}
                                />
                                <Separator />
                            </>
                        )}
                        <CustomizedButton color={'secondary'} onClick={cancelAddCommentMode}>
                            Cancel
                        </CustomizedButton>
                        <CustomizedButton color={'primary'} onClick={createComment}>
                            Add
                        </CustomizedButton>
                    </div>
                )}
            </div>
            <div className={classes.addCommentContainer}>
                <div className={classes.profileImageWrapper}>
                    <ProfileImage fullName={`${firstName} ${lastName}`} email={email} imageName={image || ''} />
                </div>
                {!commentsEditorStatus.isOpen ? (
                    <input
                        className={classes.addCommentInput}
                        placeholder="Add a comment"
                        onClick={setAddCommentMode}
                    />
                ) : (
                    <div className={classes.addCommentWrapper}>
                        <div data-testid={'comments-text-area'} className={classes.textArea}>
                            <RichTextArea
                                onEditorDescription={handleEditorDescription}
                                existingData={commentData.comment}
                                editorContentHeight={180}
                                isAutoFocus={true}
                                isError={commentError}
                                mention={{
                                    separator: ' ',
                                    trigger: '@',
                                    suggestions: assigneeOptions,
                                }}
                                placeholder={
                                    'Enter your comments here. \nType @ to mention another user and notify them about it.'
                                }
                            />
                        </div>
                    </div>
                )}
            </div>

            {sortedComments.map((item) => (
                <div key={item.updatedDate} className={classes.commentWrapper}>
                    <ProfileImage
                        fullName={`${item.assignee.firstName} ${item.assignee.lastName}`}
                        email={item.assignee.userName}
                        imageName={item.assignee.imageName || ''}
                    />
                    <div className={classes.wrapper}>
                        <div className={classes.iconsWrapper}>
                            <div className={classes.iconsHeader}>
                                <span>{`${item.firstName} ${item.lastName}`}</span>
                                <span>
                                    {moment(item.updatedDate).format(constants.formats.date.fullDateWithMilliSeconds)}
                                </span>

                                {username === item.userName && (
                                    <div className={classes.actionIcons}>
                                        <CustomizedRoundIcon
                                            size={'2.6rem'}
                                            onClick={() => {
                                                setCommentId(item.commentId);
                                                setCommentError(false);
                                                setCommentData({ ...commentData, id: item.commentId, comment: '' });
                                                resetCommentsEditorStatus();
                                            }}
                                        >
                                            <Icon name={'edit-user-profile'} size="1.6rem" />
                                        </CustomizedRoundIcon>
                                        <CustomizedRoundIcon
                                            size={'2.6rem'}
                                            onClick={() => {
                                                confirmDeleteComment(item.commentId);
                                            }}
                                        >
                                            <Icon name={'delete-icon'} size="1.6rem" />
                                        </CustomizedRoundIcon>
                                    </div>
                                )}
                            </div>

                            {commentId === item.commentId && (
                                <div className={classes.actionButtons}>
                                    <CustomizedButton
                                        color={'secondary'}
                                        onClick={() => {
                                            setCommentId(false);
                                            setCommentData({ ...commentData, id: null, comment: '' });
                                        }}
                                    >
                                        Cancel
                                    </CustomizedButton>
                                    <CustomizedButton
                                        color={'primary'}
                                        onClick={() => {
                                            updateComment(item.commentId);
                                        }}
                                    >
                                        Update
                                    </CustomizedButton>
                                </div>
                            )}
                        </div>

                        <div data-testid={'comment-text'} className={classes.commentText}>
                            {commentId === item.commentId ? (
                                <>
                                    <div className={classes.textArea}>
                                        <RichTextArea
                                            onEditorDescription={handleEditorDescription}
                                            existingData={
                                                commentData.id === item.commentId &&
                                                !isStringHasRealContent(commentData.comment)
                                                    ? commentData.comment
                                                    : item.comment
                                            }
                                            editorContentHeight={160}
                                            isAutoFocus={true}
                                            isError={commentError}
                                            mention={{
                                                separator: ' ',
                                                trigger: '@',
                                                suggestions: assigneeOptions,
                                            }}
                                        />
                                    </div>
                                </>
                            ) : (
                                <span dangerouslySetInnerHTML={{ __html: item.comment }} />
                            )}
                        </div>
                    </div>
                </div>
            ))}

            {removeCommentId && (
                <CustomizedConfirmDeleteModal
                    isShowModal={deleteCommentModalShow}
                    hideModal={() => {
                        setDeleteCommentModalShow(false);
                    }}
                    text={'Are you sure you want to delete this Comment?'}
                    onConfirm={() => handleCommentDelete(removeCommentId)}
                />
            )}
        </div>
    );
};

export default Comments;
