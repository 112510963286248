import { useRef, useLayoutEffect, useState } from 'react';
import * as d3 from 'd3';
import classes from './yaxisXaxisLollipop.module.scss';
import moment from 'moment-timezone';
import colorsVars from 'styles/colors.module.scss';
import { useThemeStatus } from 'components/Header/store/themeStatus';

type Props = {
    dataset: IDataLollipop[];
    constraints: IRel[];
    filter: number | number[];
    width?: string | number;
    height?: string | number;
    showRels: boolean;
    nowDate: number;
};

const activityStatus = {
    NOT_STARTED: 'Not Started',
    ACTIVE: 'Active',
    COMPLETED: 'Completed',
};

const YaxisXaxisLollipop = ({ dataset, filter, constraints, showRels, nowDate }: Props) => {
    const { themeStatus } = useThemeStatus();
    const textColor = themeStatus ? '#ffffff' : '#000000';

    const svgRef = useRef<SVGSVGElement>(null);
    const xaxisRef = useRef<SVGSVGElement>(null);

    const containerRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const height = 800;

    const margin = {
        top: 30,
        right: 30,
        bottom: 30,
        left: 30,
    };

    const filterFunc = (item) => item.level === 1 || (item.level >= filter[0] && item.level <= filter[1]);
    const svgHeight = height + margin.top + margin.bottom;

    const colorFunc = (d: any, source: string | null = null) => {
        // Completed tasks with End date before Now line – Green
        //
        // Active tasks with Start Date before Now line End date after Now line and not Completed – Blue
        //
        // Active tasks with End date before Now line and not Completed – Red
        //
        // Planned tasks with Start date after Now line – Black
        if (d.delayDriver && source === 'circle') return colorsVars.delayDriverColor;

        switch (d.color) {
            case 'red':
                return 'orange';
            case 'black':
                return 'lightblue';
            default:
                return d.color || 'blue';
        }
    };

    let idleTimeout;

    const idled = () => {
        idleTimeout = null;
    };

    useLayoutEffect(() => {
        function handleResize() {
            if (containerRef.current) {
                setWidth(containerRef.current.offsetWidth - margin.right);
            }
        }

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [dataset.length]);

    useLayoutEffect(() => {
        if (svgRef.current && width > 0) {
            const svg = d3.select(svgRef.current);
            const xAxis = d3.select(xaxisRef.current).select('g');

            const draw = () => {
                const data = dataset.filter(filterFunc);
                // Add X axis
                const mindate = d3.min(dataset.map((d) => d.start));
                const maxdate = d3.max(dataset.map((d) => d.end));
                const x = d3.scaleTime().range([0, width - margin.right - margin.left]);
                const y = d3.scaleLinear().range([0, svgHeight]);
                const today = nowDate ? new Date(nowDate) : new Date();
                x.domain([mindate, maxdate]);
                y.domain([0, data.length]);

                xAxis.call(d3.axisBottom(x).ticks(5));
                xAxis.select('path').attr('stroke', textColor);
                xAxis.selectAll('.tick line').attr('stroke', textColor);
                xAxis.selectAll('.tick text').attr('fill', textColor).attr('font-size', '16');

                const updateChart = (event) => {
                    const extent = event.selection;
                    if (!extent) {
                        if (!idleTimeout) return (idleTimeout = setTimeout(idled, 350)); // This allows to wait a little bit
                        x.domain([mindate, maxdate]);
                        y.domain([0, data.length]);
                    } else {
                        x.domain([x.invert(extent[0][0]), x.invert(extent[1][0])]);
                        y.domain([y.invert(extent[0][1]), y.invert(extent[1][1])]);
                        svg.select('.brush').call(brush.move, null); // This remove the grey brush area as soon as the selection has been done
                    }

                    // Update axis position
                    xAxis.transition().duration(1000).call(d3.axisBottom(x).ticks(5));
                    xAxis.select('path').attr('stroke', textColor);
                    xAxis.selectAll('.tick line').attr('stroke', textColor);
                    xAxis.selectAll('.tick text').attr('fill', textColor).attr('font-size', '16');

                    // Update right circle position
                    svg.select('.circle-right')
                        .selectAll('circle')
                        .transition()
                        .duration(1000)
                        .attr('cy', (d) => y(d.y))
                        .attr('cx', (d) => x(d.end));

                    // Update left circle position
                    svg.select('.circle-left')
                        .selectAll('circle')
                        .transition()
                        .duration(1000)
                        .attr('cy', (d) => y(d.y))
                        .attr('cx', (d) => x(d.start));

                    // Update lines position
                    svg.select('.lines')
                        .selectAll('line')
                        .transition()
                        .duration(1000)
                        .attr('y1', (d) => y(d.y))
                        .attr('y2', (d) => y(d.y))
                        .attr('x1', (d) => x(d.start))
                        .attr('x2', (d) => x(d.end));

                    // Update constraints position
                    svg.select('.constraints')
                        .selectAll('line')
                        .transition()
                        .duration(1000)
                        .attr('y1', (d) => y(d.y_start))
                        .attr('y2', (d) => y(d.y_end))
                        .attr('x1', (d) => x(d.start))
                        .attr('x2', (d) => x(d.end));

                    // Update today line position
                    svg.select('.today-line')
                        .selectAll('line')
                        .transition()
                        .duration(1000)
                        .attr('x1', x(today))
                        .attr('y1', 0)
                        .attr('x2', x(today));

                    svg.select('.today-line')
                        .selectAll('rect')
                        .transition()
                        .duration(1000)
                        .attr('x', x(today) - 50);

                    svg.select('.today-line').selectAll('text').transition().duration(1000).attr('x', x(today));
                };

                const brush = d3
                    .brush()
                    .extent([
                        [1, 1],
                        [width - margin.left - margin.right, svgHeight],
                    ])
                    .on('end', updateChart);

                // default transition
                const t = svg.transition().duration(500).delay(300);

                svg.select('.brush').call(brush);

                const tooltip = d3
                    .select('#tooltip-activities-graph')
                    .style('opacity', 0)
                    .style('position', 'absolute')
                    .style('background-color', 'white')
                    .style('border', 'solid')
                    .style('border-width', '1px')
                    .style('border-radius', '5px')
                    .style('padding', '10px')
                    .style('line-height', '24px')
                    .style('color', '#000000');

                const mouseover = function () {
                    tooltip.style('opacity', 1);
                };

                const mousemoveTask = function (event, d) {
                    tooltip
                        .html(
                            `Activity Name: ${d.title}<br />
                          Activity Code: ${d.id}<br />
                          Start: ${d.startDate}<br />
                          End: ${d.endDate}<br />
                          WBS name: ${d.wbsName}<br />
                          # Predecessors: ${d.predecessors}<br />
                          # Successors: ${d.succseccors}<br />
                          Milestone: ${d.milestone ? 'Yes' : 'No'}<br />
                          Delay Driver: ${d.delayDriver ? 'Yes' : 'No'}<br />
                          Status: ${activityStatus[d.activityStatus]}
                        `,
                        )
                        .style('left', event.layerX + 50 + 'px') // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
                        .style('top', event.layerY + 'px');
                };

                const mousemoveRel = function (event, d) {
                    tooltip
                        .html(
                            `Start Activity Code: ${d.start_wbs}<br />
                          Start Activity Name: ${d.start_title}<br />
                          End Activity Code: ${d.end_wbs}<br />
                          End Activity Name: ${d.end_title}<br />
                          Relationship Type: ${d.relationType}<br />
                        `,
                        )
                        .style('left', event.layerX + 50 + 'px') // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
                        .style('top', event.layerY + 'px');
                };

                const mouseleave = function () {
                    tooltip
                        .transition()
                        .duration(0)
                        .style('opacity', 0)
                        .transition()
                        .duration(0)
                        .style('left', '-1000px');
                };

                // handle today line
                svg.select('.today-line')
                    .selectAll('line')
                    .attr('x1', x(today))
                    .attr('y1', 0)
                    .attr('x2', x(today))
                    .attr('y2', svgHeight)
                    .style('stroke-width', 2)
                    .style('stroke', colorsVars.versionDateColor)
                    .style('fill', 'none');

                svg.select('.today-line')
                    .selectAll('rect')
                    .attr('x', x(today) - 50)
                    .attr('y', height - 25);

                svg.select('.today-line')
                    .selectAll('text')
                    .attr('x', x(today))
                    .attr('y', height - 10)
                    .attr('font-size', '14')
                    .attr('font-family', 'Roboto')
                    .attr('fill', colorsVars.versionDateColor)
                    .attr('dominant-baseline', 'top')
                    .attr('text-anchor', 'middle');

                const enterCirclePath = (d, joinType) => {
                    const r = 6;
                    const w = 24;
                    const h = 24;
                    const cx =
                        joinType === 'update' ? x(d.end) : x(new Date((d.start.getTime() + d.end.getTime()) / 2));
                    const cy = y(d.y);
                    if (d.milestone) {
                        //     return `M ${cx},${cy} L0.7,6.5 l5.2,5.4 l5.2-5.4 L5.9,1.2 z`
                        return `M${cx}, ${cy - h / 2}L${cx + w / 2},${cy}L${cx},${cy - h / 2}L${cx + w / 2},${cy}Z`; // diamond shape
                    }
                    return `M ${cx}, ${cy} m -${r}, 0 a ${r},${r} 0 1,0 ${r * 2},0 a ${r},${r} 0 1,0 -${r * 2},0`; // circle shape
                };
                // Start at the top
                // var result = 'M' + centroid.x + ',' + (centroid.y - rectH / 2);
                // Go right
                // result += 'L' + (centroid.x + rectW / 2) + ',' + centroid.y;
                // Bottom
                // result += 'L' + centroid.x + ',' + (centroid.y + rectH / 2);
                // Left
                // result += 'L' + (centroid.x - rectW / 2) + ',' + centroid.y;
                // Close the shape
                // result += 'Z';
                // return result;

                (enter) => {
                    enter
                        .append('path')
                        .attr('d', (d) => enterCirclePath(d, 'enter'))
                        .on('mouseover', mouseover)
                        .on('mousemove', mousemoveTask)
                        .on('mouseleave', mouseleave)
                        .attr('fill', (d) => colorFunc(d, 'circle'))
                        .attr('stroke', 'red')
                        .transition(t)
                        .attr('d', (d) => enterCirclePath(d, 'update'));
                };

                svg.select('.circle-right')
                    .selectAll('circle')
                    .data(data)
                    .join(
                        (enter) => {
                            enter
                                .append('circle')
                                .on('mouseover', mouseover)
                                .on('mousemove', mousemoveTask)
                                .on('mouseleave', mouseleave)
                                .attr('r', '6')
                                .attr('cx', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)))
                                .attr('cy', (d) => y(d.y))
                                .attr('fill', (d) => colorFunc(d))
                                .transition(t)
                                .attr('cx', (d) => x(d.end));
                        },
                        (update) => {
                            update
                                .transition(t)
                                .attr('cx', (d) => x(d.end))
                                .attr('cy', (d) => y(d.y))
                                .attr('fill', (d) => colorFunc(d));
                        },
                        (exit) => {
                            exit.transition(t)
                                .attr('cx', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)))
                                .remove();
                        },
                    );

                // handle left circle
                svg.select('.circle-left')
                    .selectAll('circle')
                    .data(data)
                    .join(
                        (enter) => {
                            enter
                                .append('circle')
                                .on('mouseover', mouseover)
                                .on('mousemove', mousemoveTask)
                                .on('mouseleave', mouseleave)
                                .attr('key', (d) => d.id)
                                .attr('r', '6')
                                .attr('cx', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)))
                                .attr('cy', (d) => y(d.y))
                                .attr('fill', (d) => colorFunc(d, 'circle'))
                                .transition(t)
                                .attr('cx', (d) => x(d.start));
                        },
                        (update) => {
                            update
                                .transition(t)
                                .attr('cx', (d) => x(d.start))
                                .attr('cy', (d) => y(d.y))
                                .attr('fill', (d) => colorFunc(d, 'circle'));
                        },
                        (exit) => {
                            exit.transition(t)
                                .attr('cx', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)))
                                .remove();
                        },
                    );
                svg.select('.constraints')
                    .selectAll('line')
                    .data(showRels ? constraints : [])
                    .join(
                        (enter) => {
                            enter
                                .append('line')
                                .on('mouseover', mouseover)
                                .on('mousemove', mousemoveRel)
                                .on('mouseleave', mouseleave)
                                .attr('key', (d) => d.id)
                                .attr('x1', (d) => x((d.start.getTime() + d.end.getTime()) / 2))
                                .attr('x2', (d) => x((d.start.getTime() + d.end.getTime()) / 2))
                                .attr('y1', (d) => y((d.y_start + d.y_end) / 2))
                                .attr('y2', (d) => y((d.y_start + d.y_end) / 2))
                                .attr('stroke', '#126cfc')
                                .attr('stroke-width', '3')
                                .attr('marker-end', 'url(#arrow)')
                                .transition(t)
                                .attr('x1', (d) => x(d.start))
                                .attr('x2', (d) => x(d.end))
                                .attr('y1', (d) => y(d.y_start))
                                .attr('y2', (d) => y(d.y_end));
                        },
                        (update) => {
                            update
                                .transition(t)
                                .attr('x1', (d) => x(d.start))
                                .attr('x2', (d) => x(d.end))
                                .attr('y1', (d) => y(d.y_start))
                                .attr('y2', (d) => y(d.y_end));
                        },
                        (exit) => {
                            exit.remove();
                        },
                    );
                // handle lines
                svg.select('.lines')
                    .selectAll('line')
                    .data(data)
                    .join(
                        (enter) => {
                            enter
                                .append('line')
                                .attr('key', (d) => d.id)
                                .attr('x1', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)))
                                .attr('x2', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)))
                                .attr('y1', (d) => y(d.y))
                                .attr('y2', (d) => y(d.y))
                                .attr('stroke', (d) => colorFunc(d))
                                .style('stroke-dasharray', '2, 2')
                                .attr('stroke-width', '2px')
                                .transition(t)
                                .attr('x1', (d) => x(d.start))
                                .attr('x2', (d) => x(d.end));
                        },
                        (update) => {
                            update
                                .transition(t)
                                .attr('stroke', (d) => colorFunc(d))
                                .attr('x1', (d) => x(d.start))
                                .attr('x2', (d) => x(d.end))
                                .attr('y1', (d) => y(d.y))
                                .attr('y2', (d) => y(d.y));
                        },
                        (exit) => {
                            exit.transition(t)
                                .attr('x1', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)))
                                .attr('x2', (d) => x(new Date((d.start.getTime() + d.end.getTime()) / 2)))
                                .remove();
                        },
                    );
            };

            draw();
        }
    }, [dataset, filter, width, themeStatus]);

    return (
        <div ref={containerRef} className={classes.container}>
            <div className={classes.chart}>
                <svg ref={svgRef} viewBox={`0 0 ${width} ${svgHeight}`} height={svgHeight}>
                    <defs>
                        <marker
                            id="arrow"
                            viewBox="0 0 10 10"
                            refX="9"
                            refY="5"
                            markerWidth="6"
                            markerHeight="6"
                            orient="auto"
                        >
                            <path d="M 0 0 L 10 5 L 0 10 z" fill="#126cfc" />
                        </marker>
                        <clipPath id="yAxisLollipop">
                            <rect
                                id="myRect"
                                x={0}
                                y={0}
                                width={width - margin.right - margin.left}
                                height={svgHeight - margin.top}
                            />
                        </clipPath>
                    </defs>

                    <g className="brush" transform={`translate(0, 0)`} />
                    <g clipPath="url(#yAxisLollipop)">
                        <g className="circle-left" />
                        <g className="circle-right" />
                        <g className="lines" />
                        <g className="constraints" />
                        <g className="today-line">
                            <line />
                            <rect width="100" height="20" fill="#ffffff" stroke={colorsVars.versionDateColor} />
                            <text>{moment(nowDate).format('MMM YYYY')}</text>
                        </g>
                    </g>
                </svg>
                <div id="tooltip-activities-graph" />
            </div>
            <div className={classes.xaxis}>
                <svg ref={xaxisRef} width={width} height={50}>
                    <g transform={`translate(0, 0)`} />
                </svg>
            </div>
        </div>
    );
};

export default YaxisXaxisLollipop;
