import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

interface Props {
    themeStatus: boolean;
    setThemeStatus: (value: boolean) => void;
}

const state: StateCreator<Props> = (set) => ({
    themeStatus: false,
    setThemeStatus: (theme) => set(() => ({ themeStatus: theme })),
});

export const useThemeStatus = create(persist(state, { name: 'themeStatus' }));
