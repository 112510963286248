import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';
import { Unionize } from 'utilitys/helpers/TsGeneral';

interface Props {
    isShowCriticalityAnnotation: boolean;
    isShowActivitiesAnnotation: boolean;
    update: (value: Unionize<Omit<Props, 'update'>>) => void;
}
const state: StateCreator<Props> = (set) => ({
    isShowCriticalityAnnotation: false,
    isShowActivitiesAnnotation: false,
    update: (value) => set({ ...value }),
});

export const useAnnotationStore = create(persist(state, { name: 'S-CurveAnnotationStore' }));
