import React, { useEffect, useState, useRef } from 'react';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { Redirect, useLocation, Link } from 'react-router-dom';
import PageContainer from 'components/common/PageContainer/PageContainer';
import NoData from 'components/common/NoData/noData';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { isObjectEmpty } from 'utilitys/helpers/general';
import { useProjectStore } from 'components/common/TreeProgramFilter/store/treeHeaderStore';
import { TreeProgramTopHeader } from 'components/common';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { MainContent } from 'components/common/PageContainer/MainContent';

interface Props {
    to: string;
    title: string;
}

interface Version {
    versionHashCode: number;
}
export const RedirectToLast = ({ to, title }: Props) => {
    const [last, setLast] = useState<Version | null>(null);
    const { project, contract } = useProjectStore((store) => store.selectedProject);
    const { search } = useLocation();
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        const params = {
            contractId: contract?.id,
            ids: [-1],
            projectId: project?.id,
        };

        if (project) {
            network.post(API_URL().GET_VERSION_DETAILS, params).then((response) => {
                if (mounted.current) {
                    setLast(response.data[0]);
                }
            });
        }
        return () => {
            mounted.current = false;
        };
    }, [project, contract]);

    const redirect =
        last !== null && last !== undefined && !isObjectEmpty(last) && last.hasOwnProperty('versionHashCode');
    const isInitial = last === null;
    const showNoData = last === undefined;

    if (redirect) {
        return <Redirect to={{ pathname: `${to}/${last?.versionHashCode}`, search: search }} />;
    }
    if (isInitial) {
        return (
            <PageContainer>
                <OverlayWithSpinner />
            </PageContainer>
        );
    }

    if (showNoData) {
        return (
            <PageContainer isResponsive={true}>
                <HeaderWrapper>
                    <TreeProgramTopHeader pageTitle={title} />
                </HeaderWrapper>
                <MainContent>
                    <NoData>
                        <span>No data was uploaded yet for this project. Open </span>
                        <Link to={`/dashboard/project/${projectConfig.programLibrary.link}`}>Program Library</Link>
                        <span> to upload schedule data.</span>
                    </NoData>
                </MainContent>
            </PageContainer>
        );
    }

    return null;
};
