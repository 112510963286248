import React, { useCallback, useMemo, useRef, useState } from 'react';

import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';

import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { InputOption, MyCustomSelect, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import classes from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterActivities/ClusterActivities.module.scss';
import { IClusterDataClusterActivities } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterRCFAnalysis.type';
import {
    columns,
    getFilteredRows,
    initialState as initialStateMain,
    otherSelectOptions,
    statusSelectOptions,
    TOtherSelectOptions,
    TStatusSelectOptions,
} from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/ClusterActivities/utils';
import { ConfirmSaveModal, CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import useTableCurrentState, { ITableColumnState } from 'hooks/useTableCurrentState';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import SwitchComponent from 'components/common/Switch/Switch';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import { useMutationResetCluster } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/queries/clustersMutation';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useClusterRCFAnalysisStore } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/store/clusterRCFAnalysisStore';
import { useQueryClient } from 'react-query';
import {
    CompareOptions,
    compareOptions,
} from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/CompareTo/CompareTo';

interface ClusterActivitiesProps {
    tableData?: IClusterDataClusterActivities[];
    title?: string;
    tooltipArg?: IToolTip;
    idList?: number[];
    compare: CompareOptions;
    subTitle: string;
}

const ClusterActivities = ({ tableData, tooltipArg, idList = [], compare, subTitle }: ClusterActivitiesProps) => {
    const [isInstance, setIsInstance] = useState(false);
    const tableRef = useRef(null);
    const { sendEventWithDimensions } = useEventWithDimensions();
    const { handleSnackBar } = useCustomSnackBar();
    const selectedClusterId = useClusterRCFAnalysisStore((store) => store.selectedClusterId);
    const compareKey = compareOptions.find((item) => item.value === compare)?.value;
    const [status, setStatus] = useState<readonly ISelectOption<TStatusSelectOptions>[]>(statusSelectOptions);
    const [otherOptions, setOtherOptions] = useState<readonly ISelectOption<TOtherSelectOptions>[]>([]);
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const [isCriticalPath, setIsCriticalPath] = useState<boolean>(false);
    const [modalConfirmOpen, setModalConfirmOpen] = useState<boolean>(false);
    const { mutate: mutationResetCluster } = useMutationResetCluster();
    const queryClient = useQueryClient();

    const handleStatus = useCallback((value: readonly ISelectOption<TStatusSelectOptions>[]) => {
        setStatus(value);
        sendEventWithDimensions({
            category: 'Activities Table(s)',
            action: 'Status Filter click',
            label: value.length > 0 ? value.map((i) => i.label).join(',') : 'Deselect All',
        });
    }, []);

    const handleOtherOptions = useCallback((value: readonly ISelectOption<TOtherSelectOptions>[]) => {
        setOtherOptions(value);

        sendEventWithDimensions({
            category: 'Activities Table(s)',
            action: 'Other Options click',
            label: value.length > 0 ? value.map((i) => i.label).join(',') : 'Deselect All',
        });
    }, []);

    const filteredRows = useMemo(() => {
        return tableData
            ? getFilteredRows({
                  rows: tableData,
                  statusFilter: status.map((i) => i.value),
                  otherFilter: isCriticalPath
                      ? [...otherOptions, { value: 'CriticalPath', label: 'Critical Path' }].map((i) => i.value)
                      : otherOptions.map((i) => i.value),
              })
            : [];
    }, [tableData, status, otherOptions, isCriticalPath]);

    const initialState: ITableColumnState = useMemo(() => {
        let hiddenColumns = initialStateMain.hiddenColumns;
        switch (compare) {
            case 'ITSELF':
                hiddenColumns = [...hiddenColumns, 'projectName', 'contractName', 'versionName'];
                break;
            case 'PROJECT':
                hiddenColumns = [...hiddenColumns, 'projectName'];
                break;
            case 'PORTFOLIO':
                hiddenColumns = [...hiddenColumns];
                break;
            default:
                hiddenColumns = [...hiddenColumns];
        }

        return {
            hiddenColumns,
            sortBy: initialStateMain.sortBy,
        };
    }, [idList, compare]);

    const componentKey = 'clusterActivities';
    const updatedInitialState = useTableCurrentState({ initialState, componentKey });

    const title = `Tasks in Cluster (${filteredRows.length || 0})`;

    const handleInstance = (instance) => {
        if (instance) {
            tableRef.current = instance;
            setIsInstance(true); // need for render component after getting instance
        }
    };
    const handleCriticalPath = (event) => {
        const isChecked = event.target.checked;
        setIsCriticalPath(isChecked);
    };
    const handleResetCluster = () => {
        if (selectedClusterId?.clusterId && compareKey) {
            mutationResetCluster(
                { clusterId: selectedClusterId?.clusterId, clusterType: compareKey },
                {
                    onSuccess: () => {
                        return queryClient.invalidateQueries('clustersData').then(() => {
                            handleSnackBar('Cluster tasks were updated to initial state', 'success');
                        });
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                    },
                },
            );
        }
        setModalConfirmOpen(false);
    };

    return (
        <WidgetWithTitle
            titleComponents={[
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <CustomizedTooltip
                    key={'resetClusterActivities'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Reset Cluster'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalConfirmOpen(true)}>
                            <MuiIcon icon={'settings_backup_restore'} />
                        </CustomizedRoundIcon>
                    }
                />,

                isInstance && (
                    <ExportDropdown
                        key={'export'}
                        title={title}
                        data={filteredRows}
                        tableInstance={tableRef.current}
                        subTitle={subTitle}
                    />
                ),
            ]}
            titleFilters={
                <div className={classes.filterWrapper}>
                    <div className={classes.selectWrapper}>
                        <div className={classes.title}>Status</div>
                        <MyCustomSelect<ISelectOption<TStatusSelectOptions>, true>
                            value={status}
                            options={statusSelectOptions}
                            onChange={(value) => handleStatus(value)}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isMulti={true}
                            placeholder={'Select Status...'}
                            components={{
                                Option: InputOption,
                                ValueContainer: ValueContainer,
                            }}
                        />
                    </div>
                    <div>
                        Critical Path Only
                        <SwitchComponent onChange={handleCriticalPath} checked={isCriticalPath} />
                    </div>
                    <div className={classes.selectWrapper}>
                        <div className={classes.title}>More filters</div>
                        <MyCustomSelect<ISelectOption<TOtherSelectOptions>, true>
                            value={otherOptions}
                            options={otherSelectOptions}
                            onChange={(value) => handleOtherOptions(value)}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isMulti={true}
                            placeholder={'Select More Filters...'}
                            components={{
                                Option: InputOption,
                                ValueContainer: ValueContainer,
                            }}
                        />
                    </div>
                </div>
            }
            title={title}
            tooltip={tooltipArg}
        >
            {tableData && (
                <div className={classes.tableContainer}>
                    {updatedInitialState && (
                        <div className={classes.tableContainer}>
                            <MainTable
                                getInstanceCallback={handleInstance}
                                columns={columns}
                                data={filteredRows}
                                initialState={updatedInitialState}
                                pageSize={1000}
                            />
                        </div>
                    )}

                    {tableRef.current && (
                        <CustomTableColumnsModal
                            onHideModal={() => setModalColumnsOpen(false)}
                            showModal={modalColumnsOpen}
                            tableInstance={tableRef.current}
                            initialState={initialState}
                            columns={columns}
                            componentKey={componentKey}
                        />
                    )}

                    <ConfirmSaveModal
                        isShowModal={modalConfirmOpen}
                        onHideModal={() => setModalConfirmOpen(false)}
                        onConfirm={handleResetCluster}
                        actionButtonLabel={'Yes'}
                    >
                        Are you sure you want to reset this cluster to its original tasks set?
                    </ConfirmSaveModal>
                </div>
            )}

            {!tableData && (
                <div className={classes.boxWrapper}>
                    <p>Waiting for data...</p>
                </div>
            )}
        </WidgetWithTitle>
    );
};

export default ClusterActivities;
