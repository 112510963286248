import classes from './ExcelExport.module.scss';
import { CheckboxComponent } from 'components/common/CheckboxComponent/checkboxComponent';
import { CustomizedTextArea } from 'components/common/CustomizedTextArea/CustomizedTextArea';
import { CustomizedButton } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import {
    useMutationSetExcelExportConfig,
    useQueryGetExcelExportConfig,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/queries/useQueryExcelExportConfig';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useProjectStore } from 'components/common/TreeProgramFilter/store/treeHeaderStore';

interface IFields {
    remainingDurationInDays?: boolean;
    submittedDurationComplete?: boolean;
    userData?: string;
}

export const ExcelExport = () => {
    const { contract } = useProjectStore((store) => store.selectedProject);
    const { data: excelExportConfigData, refetch } = useQueryGetExcelExportConfig({ subProgramId: contract?.id });
    const { mutate, isLoading } = useMutationSetExcelExportConfig();
    const { handleSnackBar } = useCustomSnackBar();

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm<IFields>({
        values: {
            remainingDurationInDays: excelExportConfigData?.isExtractRemainingDuration,
            submittedDurationComplete: excelExportConfigData?.isExtractPercentageComplete,
            userData: excelExportConfigData?.userData,
        },
    });

    const handleSave = (data) => {
        if (contract) {
            mutate(
                {
                    contractId: contract.id,
                    isExtractRemainingDuration: data.remainingDurationInDays,
                    isExtractPercentageComplete: data.submittedDurationComplete,
                    userData: data.userData,
                },
                {
                    onSuccess: () => {
                        handleSnackBar('Successfully updated', 'success');
                        return refetch();
                    },
                    onError: (error) => handleSnackBar(error.response.data, 'error'),
                },
            );
        }
    };

    return (
        <form onSubmit={handleSubmit(handleSave)} className={classes.excelExportContainer}>
            <span className={classes.title}>P6 Excel Export:</span>
            <div className={classes.container}>
                <div>Include</div>
                <div className={classes.checkBoxSection}>
                    <div className={classes.checkBoxWrapper}>
                        <Controller
                            render={({ field }) => <CheckboxComponent {...field} checked={field.value} />}
                            name={'remainingDurationInDays'}
                            control={control}
                        />
                        Remaining Duration
                    </div>
                    <div className={classes.checkBoxWrapper}>
                        <Controller
                            render={({ field }) => <CheckboxComponent {...field} checked={field.value} />}
                            name={'submittedDurationComplete'}
                            control={control}
                        />
                        Completed %
                    </div>
                </div>

                <div>P6 USERDATA</div>
                <div className={classes.textAreaWrapper}>
                    <Controller
                        render={({ field }) => <CustomizedTextArea {...field} maxLength={400} />}
                        name={'userData'}
                        control={control}
                    />
                </div>

                <div className={classes.actionButtons}>
                    <CustomizedButton color={'secondary'} onClick={reset}>
                        Cancel
                    </CustomizedButton>
                    <CustomizedButton type={'submit'} color={'primary'} disabled={!isDirty} isLoading={isLoading}>
                        Save
                    </CustomizedButton>
                </div>
            </div>
        </form>
    );
};
