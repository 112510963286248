import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import classes from './trackedUntrackedAction.module.scss';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import React, { useState } from 'react';
import { LoaderSpinner } from 'components/common/LodaerSpinner/LoaderSpinner';
import { useQueryClient } from 'react-query';
import { useMutationTrackUnTrackItems } from 'components/common/TrackedUntrackedAction/queries/trackedMutation';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import { useProjectStore } from 'components/common/TreeProgramFilter/store/treeHeaderStore';

interface ITrackedUntrackedActionProps {
    taskVersionHashCode: string;
    isTracked?: boolean;
    invalidateQueriesKey: string[];
}

export const TrackedUntrackedAction = ({
    isTracked = false,
    taskVersionHashCode,
    invalidateQueriesKey,
}: ITrackedUntrackedActionProps) => {
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate, isLoading } = useMutationTrackUnTrackItems();
    const queryClient = useQueryClient();

    const [isLoadingInvalidateQueries, setIsLoadingInvalidateQueries] = useState<boolean>(false);
    const { contract } = useProjectStore((store) => store.selectedProject);

    const handleTrackActivity = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        setIsLoadingInvalidateQueries(true);

        mutate(
            {
                taskVersionHashCode: taskVersionHashCode,
                toTrack: !isTracked,
                contractId: contract?.id,
            },
            {
                onSuccess: () => {
                    return Promise.all(invalidateQueriesKey.map((key) => queryClient.invalidateQueries(key))).then(
                        () => {
                            setIsLoadingInvalidateQueries(false);
                            isTracked
                                ? handleSnackBar('Removed From Track Activities', 'success')
                                : handleSnackBar('Added To Track Activities', 'success');
                        },
                    );
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                    setIsLoadingInvalidateQueries(false);
                },
            },
        );
    };

    return (
        <div className={classes.trackedUntrackedActionContainer}>
            {isLoading || isLoadingInvalidateQueries ? (
                <LoaderSpinner />
            ) : isTracked ? (
                <CustomizedTooltip
                    tooltipContent={'Tracked Activity'}
                    triggerElement={
                        <MuiIcon icon="star" color="#FFE78D" fontSize="3rem" onClick={handleTrackActivity} />
                    }
                />
            ) : (
                <CustomizedTooltip
                    tooltipContent={'Click to set as Tracked Activity'}
                    triggerElement={
                        <MuiIcon icon="star_outline" color="#7c838c" fontSize="3rem" onClick={handleTrackActivity} />
                    }
                />
            )}
        </div>
    );
};
