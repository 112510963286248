import React, { useMemo, useState } from 'react';
import classes from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/customClusteringSelection/customClusteringSelection.module.scss';
import Icon from 'components/common/Icons/icon';
import {
    CustomizedButton,
    CustomizedDropdownMenu,
    CustomizedRoundIcon,
    CustomizedModalBase,
    CustomizedConfirmDeleteModal,
    CustomizedTooltip,
} from 'components/common';
import {
    useMutationSaveDSearchDelete,
    useMutationSaveSearchWord,
} from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/queries/clustersMutation';
import useUserHook from 'hooks/useUserHook';
import {
    useQueryClustersDataBySearchWord,
    useQueryClustersSearchWordsList,
} from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/queries/clustersQuery';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useClusterRCFAnalysisStore } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/store/clusterRCFAnalysisStore';
import { useProjectStore } from 'components/common/TreeProgramFilter/store/treeHeaderStore';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useQueryState } from 'hooks/useQueryState';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { AdvancedWrapperTooltipContent } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/advancedWrapperTooltipContent/advancedWrapperTooltipContent';
import scssVariables from 'styles/variables.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import colorsVars from 'styles/colors.module.scss';
import CreatableSelect from 'react-select/creatable';
import { InputActionMeta, SingleValue } from 'react-select';
import { SelectStyles } from 'components/common/MyCustomSelect/myCustomSelect.style';
import { compareOptions } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/CompareTo/CompareTo';

const { iconColor } = colorsVars;

export const CustomClusteringSelection = () => {
    const { mediaLaptop } = scssVariables;
    const isLaptop = useMediaQuery(`(max-width: ${mediaLaptop})`);
    const [compare] = useQueryState('clusterScope');
    const { id } = useParams<{ id: string }>();
    const { idList } = useHashmapConverter(id);
    const { project } = useProjectStore((store) => store.selectedProject);
    const projectId = project?.id;
    const searchWord = useClusterRCFAnalysisStore((store) => store.searchWord);
    const { update } = useClusterRCFAnalysisStore();
    const { handleSnackBar } = useCustomSnackBar();
    const [searchValue, setSearchValue] = useState<string | undefined>(searchWord);
    const [beforeSaveModal, setBeforeSaveModal] = useState(false);
    const [searchWordId, setSearchWordId] = useState<number | null>(null);
    const [deleteSearchWordModalShow, setDeleteSearchWordModalShow] = useState(false);
    const { customerId } = useUserHook();
    const compareKey = compareOptions.find((item) => item.value === compare)?.value;
    const { data: searchWordsList, refetch } = useQueryClustersSearchWordsList(customerId);
    const mutation = useMutationSaveSearchWord();
    const mutationDeleteSavedSearch = useMutationSaveDSearchDelete();
    const getClustersDataBySearchWord = useQueryClustersDataBySearchWord({
        idList: idList,
        projectId: projectId,
        customerId: customerId,
        searchWord: searchWord,
        compare: compareKey,
    });
    const { isLoading } = getClustersDataBySearchWord;

    const handleOnSearch = () => {
        update({ searchWord: searchValue });
    };

    const handleSaveSearch = () => {
        if (searchValue && searchValue.trim().length > 0) {
            setBeforeSaveModal(true);
        }
    };

    const handleSaveSearchFinal = () => {
        mutation.mutate(
            {
                customerId: customerId,
                paFileMetaDataIds: idList,
                projectId: projectId,
                query: searchValue,
            },
            {
                onSuccess: () => {
                    refetch().then(() => {
                        handleSnackBar('Search Saved Successfully', 'success');
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Failed To Save Search', 'error');
                },
            },
        );
        setBeforeSaveModal(false);
    };

    const handleModalClose = () => {
        setBeforeSaveModal(false);
    };

    const confirmSavedFilterDeletion = () => {
        const searchValueObj = searchWordsList
            ? searchWordsList.filter((item) => item.clusterName.toLowerCase() === searchValue?.toLowerCase())
            : [];

        searchValueObj.length > 0 && setSearchWordId(searchValueObj[0].id);
        setDeleteSearchWordModalShow(true);
    };

    const handleSearchWordDelete = () => {
        mutationDeleteSavedSearch.mutateAsync(searchWordId).then(() => {
            setDeleteSearchWordModalShow(false);
            setSearchValue(undefined);
            update({ searchWord: undefined });
            refetch().then(() => {
                handleSnackBar('Search Deleted Successfully', 'success');
            });
        });
    };

    const isEnableDelete =
        searchWordsList &&
        searchWordsList.some((item) => item.clusterName.toLowerCase() === searchValue?.toLowerCase());

    const handleOnChange = (value: SingleValue<{ label: string; value: string }>) => {
        if (!value) {
            setSearchValue(undefined);
            update({ searchWord: undefined });
        } else {
            setSearchValue(value.value);
            update({ searchWord: value.value });
        }
    };

    const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === 'input-blur' && actionMeta.prevInputValue !== newValue) {
            setSearchValue(actionMeta.prevInputValue);
            update({ searchWord: actionMeta.prevInputValue });
        }
    };

    const selectValue = useMemo(() => {
        return searchValue ? { label: searchValue, value: searchValue } : null;
    }, [searchValue]);

    return (
        <div className={classes.customClusteringContainer}>
            <h3>Search: </h3>
            <CustomizedTooltip
                tooltipPosition={TooltipPosition.Bottom}
                contentStyles={{
                    maxWidth: isLaptop ? '400px' : '650px',
                }}
                key={'advancedInfo'}
                tooltipContent={<AdvancedWrapperTooltipContent />}
                triggerElement={
                    <CustomizedRoundIcon enableHover={true} size={'2.4rem'}>
                        <Icon name={'info-icon'} size="14" color={iconColor} />
                    </CustomizedRoundIcon>
                }
            />
            <div className={classes.searchInputContainer}>
                <CreatableSelect
                    value={selectValue}
                    options={searchWordsList?.map((item) => ({
                        label: item.clusterName,
                        value: item.clusterName,
                    }))}
                    isClearable={true}
                    onChange={handleOnChange}
                    onInputChange={handleInputChange}
                    styles={SelectStyles}
                />
            </div>

            <CustomizedButton size={'medium'} color={'primary'} onClick={handleOnSearch} isLoading={isLoading}>
                Search
            </CustomizedButton>

            <CustomizedDropdownMenu
                disabled={searchValue?.trim().length === 0}
                triggerElement={
                    <CustomizedRoundIcon size={'3rem'} disabled={searchValue?.trim().length === 0}>
                        <Icon name="three-dot-icon" />
                    </CustomizedRoundIcon>
                }
            >
                <span onClick={handleSaveSearch}>Save Search</span>
                {isEnableDelete && <span onClick={confirmSavedFilterDeletion}>Delete</span>}
            </CustomizedDropdownMenu>

            <CustomizedModalBase
                handleModalClose={handleModalClose}
                isModalOpen={beforeSaveModal}
                modalStyles={{ width: '45rem' }}
            >
                <div className={classes.saveFilterModalContainer}>
                    <div className={classes.title}>Save Search</div>
                    <div className={classes.searchValue}>{searchValue}</div>

                    <div className={classes.footer}>
                        <div>
                            <CustomizedButton size={'medium'} color={'secondary'} onClick={handleModalClose}>
                                Cancel
                            </CustomizedButton>
                            <CustomizedButton size={'medium'} color={'primary'} onClick={handleSaveSearchFinal}>
                                Save
                            </CustomizedButton>
                        </div>
                    </div>
                </div>
            </CustomizedModalBase>

            <CustomizedConfirmDeleteModal
                isShowModal={deleteSearchWordModalShow}
                hideModal={() => {
                    setDeleteSearchWordModalShow(false);
                }}
                text={'Are you sure you want to delete this Search?'}
                onConfirm={handleSearchWordDelete}
                isLoading={mutationDeleteSavedSearch.isLoading}
            />
        </div>
    );
};
